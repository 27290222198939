import { useAlert, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react'
import { AlertButton } from 'react-native'
import { useRecoilState } from 'recoil'

import api from '../apis/apiCalls'
import type { UserSettings } from '../apis/types/apiResponseTypes'
import GeoLocationUtils from '../utils/GeoLocationUtils'
import { infoboxFilterPreferenceAtom } from '../utils/stateManager'
import { useActivities } from './ActivitiesContext'

interface UserSettingsContextType {
  userSettings: UserSettings | undefined
  set: (userSettings: UserSettings) => void
  load: () => Promise<UserSettings | undefined>
}

export const UserSettingsContext = createContext<UserSettingsContextType | undefined>(undefined)

type Props = { children: ReactNode | (({ loading }: { loading: boolean }) => ReactNode) }

export const UserSettingsProvider = ({ children }: Props) => {
  const { alert } = useAlert()
  const { i18n } = useLanguage()
  const { sessionKey } = useAuthentication()
  const { isLoggedIn, logout } = useAuthentication()
  const { loadActivities } = useActivities()

  const [filter, setFilter] = useRecoilState(infoboxFilterPreferenceAtom(sessionKey))

  const [settingsLoading, setSettingsLoading] = useState(true)
  const [userSettings, setUserSettings] = useState<UserSettings | undefined>(undefined)

  const alertButtons: AlertButton[] = [
    {
      style: 'destructive',
      onPress: () => {
        logout().catch(console.error)
      },
      text: i18n.t('LOGOUT'),
    },
    {
      style: 'cancel',
      onPress: onLoginSuccess,
      text: i18n.t('RETRY'),
    },
  ]

  useEffect(() => {
    if (isLoggedIn) {
      onLoginSuccess()
    }
  }, [isLoggedIn])

  function onLoginSuccess() {
    GeoLocationUtils.requestPermission().catch(console.error)

    load()
      .then(result => {
        if (!result?.employee?.id) {
          throw new Error('ERROR_NO_USER')
        }

        if (!result.isEmployeeTimeManualRecordingActive && !result.isEmployeeTimeOnlineRecordingActive && !result.isPresenceTimeRecordingActive) {
          throw new Error('ERROR_NO_RECORDING_ACTIVE')
        }

        const found = result.documentTypesAvailable.find(available => available === filter)
        if (!found) {
          const firstFilter = result.documentTypesAvailable[0]
          firstFilter && setFilter(firstFilter)
        }
        if (result.isEmployeeTimeManualRecordingActive || result.isEmployeeTimeOnlineRecordingActive) return loadActivities()
        return
      })
      .then(() => {
        setSettingsLoading(false)
      })
      .catch((reason: unknown) => {
        console.error(reason)
        if ((reason as Error)?.message === 'ERROR_NO_USER') {
          alert(i18n.t('ERROR'), i18n.t('ERROR_NO_EMPLOYEE_LINKED_TO_USER'), alertButtons)
        } else if ((reason as Error)?.message === 'ERROR_NO_RECORDING_ACTIVE') {
          alert(i18n.t('ERROR'), i18n.t('ERROR_NO_TIME_RECORDING_ACTIVE'), alertButtons)
        } else {
          alert(i18n.t('ERROR'), i18n.t('ERROR_LOADING_USER_SETTINGS'), alertButtons)
        }
      })
  }

  async function load() {
    const result = await api.getUserSettings()
    setUserSettings(result)

    return result
  }

  const contextValue = useMemo(
    () => ({
      userSettings: userSettings,
      set: setUserSettings,
      load: load,
    }),
    [userSettings]
  )

  return (
    <UserSettingsContext.Provider value={contextValue}>
      {typeof children === 'function' ? children({ loading: isLoggedIn === false ? false : settingsLoading }) : children}
    </UserSettingsContext.Provider>
  )
}
