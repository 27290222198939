import { addDays, eachDayOfInterval, eachWeekOfInterval, formatDuration, intervalToDuration, subDays } from 'date-fns'

import { CONSTANTS } from '../constants/Constants'
import DateUtils from './DateUtils'

export enum TimeFormat {
  TIME = 'time',
  TIME_WITH_DIMENSIONS = 'timeWithDimensions',
  COUNTER = 'counter',
}

const timeElem = ['m', 'h']

const TimeUtils = {
  formatTime(time: Date, seconds?: boolean) {
    if (!time) return ''
    return DateUtils.formatDate(time, seconds ? 'HH:mm:ss' : 'HH:mm')
  },
  getDateForRequest(date?: Date) {
    const useDate = date ?? new Date()
    return DateUtils.formatDate(useDate, 'yyyy-MM-dd') ?? ''
  },
  getMinutesOfDay(date: Date) {
    return date.getMinutes() + date.getHours() * 60
  },
  getSecondsOfDay(date: Date) {
    return date.getSeconds() + date.getMinutes() * 60 + date.getHours() * 3600
  },
  getCurrentMinutes() {
    return this.getMinutesOfDay(new Date())
  },
  getCurrentSeconds() {
    return this.getSecondsOfDay(new Date())
  },
  revertMinutesToDate(minutes: number, useDate?: Date) {
    const date = useDate ?? new Date()
    date.setHours(Math.floor(minutes / 60))
    date.setMinutes(minutes % 60)
    date.setSeconds(0)
    return date
  },
  revertSecondsToDate(seconds: number, useDate?: Date) {
    const date = useDate ?? new Date()
    date.setHours(Math.floor(seconds / 3600))
    const minutes = seconds % 3600
    date.setMinutes(Math.floor(minutes / 60))
    date.setSeconds(minutes % 60)
    return date
  },
  formatDurationMinutes(minutes: number, withDimensions?: boolean) {
    const minutesABS = Math.abs(Math.round(minutes))
    const totalHours = Math.floor(minutesABS / 60)
    const totalMinutes = minutesABS - totalHours * 60
    let result = `${minutes < 0 ? '-' : ''}`
    if (totalHours > 0 || !withDimensions) {
      result += totalHours.toString().padStart(withDimensions ? 1 : 2, '0')
      result += withDimensions ? 'h ' : ':'
    }
    result += totalMinutes.toString().padStart(withDimensions ? 1 : 2, '0')
    result += withDimensions ? 'm ' : ''
    return result
  },
  formatDurationSeconds(seconds: number, showSeconds?: boolean) {
    const secondsABS = Math.abs(Math.round(seconds))
    const totalHours = Math.floor(secondsABS / 3600)
    const totalMinutes = Math.floor((secondsABS - totalHours * 3600) / 60)
    const totalSeconds = secondsABS - totalHours * 3600 - totalMinutes * 60

    let result = `${seconds < 0 ? '-' : ''}${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}`
    if (showSeconds) result = `${result}:${totalSeconds.toString().padStart(2, '0')}`
    return result
  },
  formatMinutes(minutes: number, useDate?: Date, showNegatives?: boolean) {
    const minutesABS = Math.abs(minutes)
    return `${showNegatives && minutes < 0 ? '-' : ''}${this.formatTime(this.revertMinutesToDate(minutesABS, useDate))}`
  },
  formatSeconds(seconds: number, useDate?: Date, hideSeconds?: boolean, showNegatives?: boolean) {
    const secondsABS = Math.abs(seconds)
    return `${showNegatives && seconds < 0 ? '-' : ''}${this.formatTime(this.revertSecondsToDate(secondsABS, useDate), !hideSeconds)}`
  },
  secondsToTime(seconds: number, outputFormat = TimeFormat.COUNTER) {
    // TODO duration > 24h is viable and should work
    if (seconds > CONSTANTS.maxSeconds) {
      return 'NaN'
    }

    if (outputFormat === TimeFormat.TIME_WITH_DIMENSIONS && seconds < 60) {
      return '< 1m'
    }

    //To Display same time as radix the seconds have to be rounded to whole minutes
    if (outputFormat === TimeFormat.TIME) {
      seconds = this.roundSecondsToWholeMinute(seconds)
    }

    const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

    const zeroPad = (num: number | undefined) => String(num).padStart(2, '0')

    const format: string[] = []

    if (duration.hours !== 0) {
      format.push('hours')
      format.push('minutes')
    } else {
      if (duration.minutes !== 0) {
        format.push('minutes')
      }
    }

    const formatted = formatDuration(duration, {
      format: format,
      zero: true,
      delimiter: ':',
      locale: {
        formatDistance: (_test, count: number, ..._pros) => zeroPad(count),
      },
    })

    if (outputFormat === TimeFormat.TIME_WITH_DIMENSIONS) {
      return formatted
        .split(':')
        .map(elem => {
          return elem.replace(/0(\d+)/, '$1')
        })
        .reverse()
        .reduce((acc, elem, index) => {
          return `${elem}${timeElem[index] ?? ''}${acc === '' ? '' : ' '}` + acc
        }, '')
    }

    if (formatted.indexOf('0') === 0 && outputFormat === TimeFormat.COUNTER) {
      return formatted.replace('0', '')
    }

    if (outputFormat === TimeFormat.TIME) {
      if (format.length === 0) {
        return '00:00'
      } else if (formatted.length === 2) {
        return `00:${formatted}`
      } else if (formatted.length === 3) {
        return `0:${formatted}`
      }
    }

    return formatted
  },
  minutesToTime(minutes: number, outputFormat = TimeFormat.COUNTER) {
    return TimeUtils.secondsToTime(minutes * 60, outputFormat)
  },
  getWeek(startDate: Date) {
    return eachDayOfInterval({
      start: startDate,
      end: addDays(startDate, 6),
    })
  },
  getWeekStart(startDate: Date, weekSpan: number) {
    if (weekSpan % 2 === 0 || weekSpan < 3) {
      console.error('weekSpan must be odd and > 2')
      return []
    }

    return eachWeekOfInterval(
      {
        start: subDays(startDate, 7 * Math.floor(weekSpan / 2)),
        end: addDays(startDate, 7 * Math.floor(weekSpan / 2)),
      },
      { weekStartsOn: 1 }
    )
  },
  subtractMinutes(from: number | undefined, until: number | undefined) {
    if (!Number.isInteger(from) || !Number.isInteger(until)) return 0
    //@ts-ignore from and until are checked by Number.isInteger
    if (from > until) return 24 * 60 - (from - until)
    //@ts-ignore from and until are checked by Number.isInteger
    return until - from
  },
  subtractSeconds(from: number | undefined, until: number | undefined) {
    if (!Number.isInteger(from) || !Number.isInteger(until)) return 0
    //@ts-ignore from and until are checked by Number.isInteger
    if (from > until) return 24 * 3600 - (from - until)
    //@ts-ignore from and until are checked by Number.isInteger
    return until - from
  },
  roundSecondsToMinutes(seconds: number) {
    if (!seconds) return 0
    return Math.round(seconds / 60)
  },
  /**
   * @returns input seconds rounded to whole minutes. f.ex 63 will return 60
   */
  roundSecondsToWholeMinute(seconds: number) {
    return this.roundSecondsToMinutes(seconds) * 60
  },
}

export default TimeUtils
