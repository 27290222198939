import { IM, IMStyle, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { Modal, StyleSheet } from 'react-native'
import RNTimePicker from 'react-time-picker'

import 'react-time-picker/dist/TimePicker.css'

import useKeyPress from './hooks/useKeyPress'

interface Props {
  controller: ModalController<{ time: Date }>
  setTime: (time: Date) => void
}

export default function TimePicker({ controller, setTime }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const [value, setValue] = useState('')

  useKeyPress(
    {
      key: ['Enter'],
      cb: () => {
        handleAccept()
      },
    },
    [value]
  )

  useKeyPress(
    {
      key: ['Esc'],
      cb: () => {
        handleAbort()
      },
    },
    [value]
  )

  function handleAbort() {
    setValue('')
    controller.close()
  }

  const handleAccept = () => {
    if (value !== '') {
      const [h, m] = value.split(':')
      const date = new Date()
      date.setHours(parseInt(h, 10))
      date.setMinutes(parseInt(m, 10))
      date.setSeconds(0)
      date.setMilliseconds(0)
      setTime(date)
    } else if (controller.data?.time) {
      setTime(controller.data.time)
    }
    setValue('')
    controller.close()
  }

  return (
    <Modal visible={controller.isShown} onRequestClose={() => controller.close()} transparent>
      <IM.View style={styles.container}>
        <IM.View style={[{ backgroundColor: theme.modal.background, borderColor: theme.card.headBackground }, styles.modal]}>
          <IM.View spacing="bottom" style={styles.titleContainer}>
            <IM.Text style={styles.title}>{i18n.t('SELECT_TIME')}</IM.Text>
            <IM.View style={styles.divider} />
            <IM.PressableIcon icon={['far', 'times']} size={20} onPress={handleAbort} style={styles.icon} />
            {(value || controller.data?.time) && <IM.PressableIcon icon={['far', 'check']} size={20} onPress={handleAccept} style={styles.icon} />}
          </IM.View>
          <RNTimePicker
            autoFocus
            onChange={newTime => setValue(newTime as string)}
            value={controller.data?.time ?? ''}
            disableClock
            locale="de" // always de to avoid 12h format
            clearIcon={null}
          />
        </IM.View>
      </IM.View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#00000050',
  },
  modal: {
    minWidth: 250,
    justifyContent: 'center',
    borderRadius: IMStyle.layout.borderRadius,
    shadowColor: '#0e1216',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 20,
    padding: 25,
    borderWidth: 0,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular,
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  titleContainer: { flexDirection: 'row', flex: 1 },
  icon: {
    marginTop: -8,
    marginRight: -8,
  },
  divider: {
    flexGrow: 1,
  },
})
