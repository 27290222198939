import { IM, IMStyle } from '@infominds/react-native-components'
import React, { memo } from 'react'

interface Props {
  initials: string
  size?: number
}

const EmployeeBadgePlaceholderText = memo(function EmployeeBadge({ initials, size }: Props) {
  return (
    <IM.Text
      style={{
        color: IMStyle.palette.white,
        fontSize: (size ?? 20) * 0.5,
      }}
      allowFontScaling={false}>
      {initials}
    </IM.Text>
  )
})

export default EmployeeBadgePlaceholderText
