import { IM, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import IMContentCard from '../../components/Card/IMContentCard'
import DataBar from '../../components/DataBar'
import DurationDisplay from '../../components/DurationDisplay'
import NoEntry from '../../components/NoEntry'
import { usePresenceTime } from '../../contexts/PresenceTimeContext'

export default function PresenceTimeActivitiesCard() {
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const presenceTime = usePresenceTime()
  const timeTarget = presenceTime.presenceTimeInfo?.minutesTarget ?? 0
  const activityTimesSum = useMemo(
    () => Utils.sum(presenceTime.presenceTimeInfo?.activityTimes, time => time.minutes ?? 0),
    [presenceTime.presenceTimeInfo?.activityTimes]
  )

  const activityTimesToShow = useMemo(
    () => presenceTime.presenceTimeInfo?.activityTimes?.filter(t => !!t.minutes && t.minutes > 0) ?? [],
    [presenceTime.presenceTimeInfo?.activityTimes]
  )

  function getColorByIndex(index: number) {
    return theme.randomColors[index % theme.randomColors.length]
  }

  return (
    <IMContentCard title={i18n.t('ACTIVITY_TIMES')} spacing="bottom">
      <IM.View spacing="all">
        {!!activityTimesToShow.length && (
          <>
            {activityTimesToShow.map((activity, index) => (
              <DurationDisplay
                key={`PresenceTimeActivity${activity.activityTypeCode ?? ''}${activity.activityTypeId}`}
                name={activity.activityTypeDescription?.trim() ?? ''}
                color={getColorByIndex(index)}
                duration={activity.minutes ?? 0}
              />
            ))}
            <DurationDisplay name={i18n.t('TOTAL')} duration={activityTimesSum} spacing="vertical" primary />
            <DataBar elements={activityTimesToShow} totalValue={timeTarget} elementDataProvider={activity => ({ value: activity.minutes ?? 0 })} />
          </>
        )}
        {!presenceTime.presenceTimeInfo?.activityTimes?.length && <NoEntry description={i18n.t('NO_TIMES')} />}
      </IM.View>
    </IMContentCard>
  )
}
