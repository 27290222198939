import { IM, IMStyle } from '@infominds/react-native-components'
import { addWeeks, subWeeks } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { LayoutChangeEvent, Platform, StyleSheet } from 'react-native'
import { useSetRecoilState } from 'recoil'

import { HistoryDisplayMode } from '../../../contexts/HistoryContext'
import useIsPresenceTimeEnabled from '../../../hooks/useIsPresenceTimeEnabled'
import useLayout from '../../../hooks/useLayout'
import { historyDisplayMode } from '../../../utils/stateManager'
import { UpdateSources } from './context/CalendarContext'
import DateSelector from './DateSelector'
import { CALENDAR_DAY_COLOR } from './Day'
import GoToToday from './GoToToday'
import useCalendar from './hooks/useCalendar'
import WeekList from './WeekList'

export interface Dimension {
  height: number
  width: number
}

export default function WeekCalendar() {
  const { isSmallDevice } = useLayout()
  const { date, setDate } = useCalendar()
  const isPresenceTimeEnabled = useIsPresenceTimeEnabled()
  const setDisplayMode = useSetRecoilState(historyDisplayMode)

  const [containerDimensions, setContainerDimensions] = useState<Dimension>({ height: 0, width: 0 })

  useEffect(() => {
    setDate(new Date(), UpdateSources.PICKER_SELECT)
  }, [])

  const onLayout = (ev: LayoutChangeEvent) => {
    const { width, height } = ev.nativeEvent.layout
    setContainerDimensions({ width, height })
  }

  const handlePress = (type: 'increase' | 'decrease') => {
    setDate(type === 'increase' ? addWeeks(date, 1) : subWeeks(date, 1), UpdateSources.PICKER_SELECT)
  }

  return (
    <IM.View style={styles.weekCalendarContainer}>
      <DateSelector />
      {Platform.OS === 'web' && (
        <IM.PressableIcon
          icon={['fal', 'angle-left']}
          color={CALENDAR_DAY_COLOR}
          style={styles.icon}
          onPress={() => {
            handlePress('decrease')
          }}
        />
      )}
      <IM.View style={styles.weekCalendar} onLayout={onLayout}>
        {containerDimensions.width !== 0 && containerDimensions.height !== 0 && (
          <WeekList height={containerDimensions.height} pageWidth={containerDimensions.width} />
        )}
      </IM.View>
      {Platform.OS === 'web' && (
        <IM.PressableIcon
          icon={['fal', 'angle-right']}
          color={CALENDAR_DAY_COLOR}
          style={styles.icon}
          onPress={() => {
            handlePress('increase')
          }}
        />
      )}
      {!isSmallDevice && <GoToToday />}
      {isPresenceTimeEnabled && (
        <IM.View>
          <IM.PressableIcon
            icon={['fal', 'calendar-clock']}
            size={22}
            color={IMStyle.palette.white}
            onPress={() => setDisplayMode(HistoryDisplayMode.Month)}
            style={styles.calendarButtonView}
          />
        </IM.View>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: IMStyle.palette.grey },
  icon: { alignItems: 'center', height: '100%' },
  weekCalendarContainer: {
    flexDirection: 'row',
    height: 64,
  },
  weekCalendar: { flex: 1 },
  calendarButtonView: {
    justifyContent: 'center',
    height: '100%',
    flex: 1,
    margin: 8,
    marginRight: 0,
    marginLeft: 5,
    borderRadius: IMStyle.layout.borderRadius,
  },
})
