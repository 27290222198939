import { DefaultTheme, IM, IMStyle, useLanguage, Utils } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import type { EmployeeTime } from '../../apis/types/apiResponseTypes'
import { useEmployeeTime } from '../../contexts/EmployeeTimeContext'
import DateUtils from '../../utils/DateUtils'
import TimeUtils, { TimeFormat } from '../../utils/TimeUtils'
import Timer from '../Timer'

export type HomeHeaderProps = {
  times: EmployeeTime[] | undefined
  onNewTimePress: () => void
}

export default function HomeHeader({ times, onNewTimePress }: HomeHeaderProps) {
  const { i18n, language } = useLanguage()
  const employeeTime = useEmployeeTime()

  const fontColor = DefaultTheme.dark.text
  const startTime = useMemo(() => {
    if (employeeTime.currentTime?.from && employeeTime.currentTime.until) return employeeTime.currentTime.until - employeeTime.currentTime.from
    return employeeTime.currentTime?.from ?? 0
  }, [employeeTime.currentTime?.from])

  const totalToday = Utils.sum(times, time => time.duration * 60)

  //Active Time Header
  if (employeeTime.isTimeActive) {
    return (
      <Pressable style={styles.main} onPress={onNewTimePress}>
        <IM.Icon size={22} color={fontColor} icon={['fal', 'stopwatch']} />
        <Timer textStyle={[{ color: fontColor }, styles.activeTimerText]} style={styles.activeTimer} timeS={startTime} spacing="left" runTimer />
        <IM.View style={styles.activeTimeStartView}>
          <IM.Text style={[{ color: fontColor }, styles.infoText]}>{`${i18n.t('START')}: `}</IM.Text>
          <IM.Text style={[{ color: fontColor }, styles.activeTimerStartTime]}>
            {TimeUtils.secondsToTime(employeeTime.currentTime?.from ?? 0, TimeFormat.TIME)}
          </IM.Text>
        </IM.View>
      </Pressable>
    )
  }

  //Default Header
  return (
    <IM.View style={[styles.main, styles.inactiveView]}>
      <IM.Text style={[{ color: fontColor }, styles.text]}>{DateUtils.formatDate(new Date(), 'dd MMMM', language)}</IM.Text>
      <IM.View style={styles.activeTimeStartView}>
        {!!totalToday && (
          <>
            <IM.Text style={[{ color: fontColor }, styles.infoText]}>{`${i18n.t('TOTAL')}: ${TimeUtils.secondsToTime(
              totalToday,
              TimeFormat.TIME_WITH_DIMENSIONS
            )}`}</IM.Text>
          </>
        )}
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    alignContent: 'flex-end',
  },
  activeTimeStartView: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  inactiveView: {
    justifyContent: 'space-between',
  },
  text: {
    fontSize: IMStyle.typography.fontSizeRegular,
    alignSelf: 'flex-end',
  },
  activeTimer: {
    flex: 1,
  },
  activeTimerText: {
    fontSize: IMStyle.typography.fontSizeRegular,
    alignSelf: 'flex-end',
  },
  infoText: {
    marginBottom: 3,
    fontSize: IMStyle.typography.fontSizeSmall,
  },
  activeTimerStartTime: {
    fontSize: IMStyle.typography.fontSizeRegular,
  },
  inactiveTimerText: {
    fontSize: IMStyle.typography.fontSizeRegular,
    alignSelf: 'flex-end',
  },
  starTimeText: {
    alignSelf: 'flex-end',
    textAlign: 'right',
    flex: 1,
  },
})
