import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'

import './App.scss'

import { disableReactDevTools } from '@fvilers/disable-react-devtools'

if (!__DEV__) {
  // This is a security feature to prevent the user the see each component state in the production app
  disableReactDevTools()
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
