import { IM, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { FlatList, Platform } from 'react-native'

import AdditionalRepaymentKeyCard from '../../../cards/PresenceTime/AdditionalRepaymentKeyCard'
import IMRefreshControl from '../../../components/IMRefreshControl'
import NoEntry from '../../../components/NoEntry'
import { usePresenceTime } from '../../../contexts/PresenceTimeContext'
import useLayout from '../../../hooks/useLayout'

type Props = {
  loading: boolean
  setLoading: (b: boolean) => void
}

export default function AdditionalRepaymentsTabView({ loading, setLoading }: Props) {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout()
  const { additionalRepaymentEntries, loading: loadingPresenceTime, load } = usePresenceTime()

  return (
    <FlatList
      data={additionalRepaymentEntries}
      keyExtractor={item => item.key.id}
      ListFooterComponent={
        <IM.View>
          {!loadingPresenceTime && !additionalRepaymentEntries.length && <NoEntry description={i18n.t('NO_ADDITIONAL_REPAYMENT_KEYS')} />}
        </IM.View>
      }
      renderItem={({ item }) => (
        <AdditionalRepaymentKeyCard
          additionalRepayment={item}
          spacing={!isSmallDevice && item.key.id === additionalRepaymentEntries[0].key.id ? 'all' : ['horizontal', 'bottom']}
        />
      )}
      refreshControl={
        Platform.OS !== 'web' ? (
          <IMRefreshControl
            refreshing={loading}
            onRefresh={() => {
              setLoading(true)
              load()
                .catch(console.error)
                .finally(() => setLoading(false))
            }}
          />
        ) : undefined
      }
    />
  )
}
