import { IM, useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { ScreenHeader } from '../../components/ScreenHeader'
import { InfoBoxStackScreenProps } from '../../navigation/type'
import InfoBoxFolderView from '../../views/InfoBox/InfoBoxFolderView'

const InfoBoxFolderScreen = ({ route, navigation }: InfoBoxStackScreenProps<'InfoboxFolder'>) => {
  const { i18n } = useLanguage()

  return (
    <IM.Screen
      screenHeader={
        <ScreenHeader goBack={navigation.goBack} title={route.params.document ? route.params.document.code : i18n.t('NO_DOCUMENT_SELECTED')} />
      }>
      {route.params.document && <InfoBoxFolderView document={route.params.document} />}
    </IM.Screen>
  )
}

export default InfoBoxFolderScreen
