import { IM, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Platform, ScrollView, StyleSheet } from 'react-native'
import { serializeError } from 'serialize-error'

import api from '../../../apis/apiCalls'
import IMRefreshControl from '../../../components/IMRefreshControl'
import LoadingIcon from '../../../components/LoadingIcon'
import Pressable from '../../../components/Pressable'
import TimeNote from '../../../components/TimeNote'
import { usePresenceTime } from '../../../contexts/PresenceTimeContext'
import { useToast } from '../../../contexts/ToastReferenceContext'
import useLayout from '../../../hooks/useLayout'
import useUserSettings from '../../../hooks/useUserSettings'

export interface NotesTabViewRef {
  checkUnsaved: () => boolean
}

type Props = {
  loading: boolean
  setLoading: (b: boolean) => void
}

function NotesTabView({ loading, setLoading }: Props, ref: ForwardedRef<NotesTabViewRef>) {
  const toast = useToast()
  const { alert } = useAlert()
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout()
  const { userSettings } = useUserSettings()
  const { presenceTimeInfo, loading: loadingPresenceTime, load } = usePresenceTime()

  const [note, setNote] = useState('')
  const [requesting, setRequesting] = useState(false)
  const [requestError, setRequestError] = useState<string>()

  useImperativeHandle(ref, () => ({
    checkUnsaved: () => {
      if (!edited) return true

      alert(i18n.t('UNSAVED_CHANGES'), i18n.t('UNSAVED_CHANGES_DESCRIPTION'), [
        { isPreferred: true, text: i18n.t('SAVE'), onPress: handleSave },
        { style: 'cancel', text: i18n.t('CANCEL') },
      ])

      return false
    },
  }))

  useEffect(() => {
    if (loadingPresenceTime) return

    setNote(presenceTimeInfo?.annotation ?? '')
  }, [loadingPresenceTime])

  useEffect(() => {
    if (!requestError || requestError === '') return

    toast.show(requestError, { type: 'danger' })
  }, [requestError])

  const handleSave = () => {
    if (!presenceTimeInfo || !presenceTimeInfo.date || !userSettings) return

    setRequesting(true)
    setRequestError(undefined)

    api
      .editPresenceTimeOfDay({ date: presenceTimeInfo.date, annotation: note, employeeId: userSettings.employee.id })
      .then(() =>
        load()
          .catch(console.error)
          .finally(() => setRequesting(false))
      )
      .catch(err => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setRequestError(serializeError(err).Message as string)
        setRequesting(false)
      })
  }

  const handleRestore = () => setNote(presenceTimeInfo?.annotation ?? '')

  if (!userSettings) return <></>

  const edited = (presenceTimeInfo?.annotation ?? '') !== note && requesting === false

  return (
    <ScrollView
      refreshControl={
        Platform.OS !== 'web' ? (
          <IMRefreshControl
            refreshing={loading}
            onRefresh={() => {
              setLoading(true)
              load()
                .catch(console.error)
                .finally(() => setLoading(false))
            }}
          />
        ) : undefined
      }>
      <TimeNote value={note} setValue={setNote} spacing={!isSmallDevice ? 'all' : ['horizontal', 'bottom']} />
      <IM.View style={styles.main}>
        <Pressable disabled={!edited} style={styles.pressable} onPress={handleRestore}>
          <IM.Text secondary={!edited}>{i18n.t('RESTORE')}</IM.Text>
          <IM.Icon icon={['fal', 'arrow-turn-left']} style={styles.icon} size={14} color={!edited ? theme.textDetail : theme.text} />
        </Pressable>
        <Pressable onPress={handleSave} disabled={!edited} style={styles.pressable}>
          <IM.Text secondary={!edited}>{i18n.t('SAVE')}</IM.Text>
          {requesting ? (
            <LoadingIcon size={14} style={styles.icon} />
          ) : (
            <IM.Icon icon={['fal', 'save']} style={styles.icon} size={14} color={!edited ? theme.textDetail : theme.text} />
          )}
        </Pressable>
      </IM.View>
    </ScrollView>
  )
}

export default forwardRef(NotesTabView)

const styles = StyleSheet.create({
  main: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 6 },
  pressable: { flex: 1, flexDirection: 'row', alignItems: 'center' },
  icon: { marginLeft: 4 },
})
