import { DefaultTheme, IM, IMStyle, useLanguage, Utils } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { usePresenceTime } from '../../contexts/PresenceTimeContext'
import DateUtils from '../../utils/DateUtils'
import TimeUtils from '../../utils/TimeUtils'
import useCalendar from '../Infominds/Calendar/hooks/useCalendar'

interface Props {
  mode: 'home' | 'history'
}

export default function PresenceTimeHeader({ mode }: Props) {
  const { date } = useCalendar()
  const { i18n, language } = useLanguage()
  const presenceTime = usePresenceTime()

  const fontColor = DefaultTheme.dark.text

  const totalToday = useMemo(
    () => Utils.sum(presenceTime.presenceTimeEntries, key => Utils.sum(key.times, time => time.duration ?? 0)),
    [presenceTime.presenceTimeEntries]
  )
  const totalTodayText = useMemo(() => TimeUtils.formatDurationMinutes(TimeUtils.roundSecondsToMinutes(totalToday)), [totalToday])

  return (
    <IM.View style={styles.main}>
      <IM.View style={styles.dateView}>
        <IM.Text style={[{ color: fontColor }, styles.text]}>
          {DateUtils.formatDate(mode === 'home' ? new Date() : date, 'dd MMMM', language)}
        </IM.Text>
      </IM.View>
      <IM.View style={styles.totalTimeView}>
        <IM.View style={styles.totalView}>
          <IM.Text style={[{ color: fontColor }, styles.totalText]}>{i18n.t('TOTAL')}</IM.Text>
        </IM.View>
        <IM.Text primary style={[{ color: fontColor }, styles.infoText]}>
          {totalTodayText}
        </IM.Text>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  dateView: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  totalTimeView: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    flex: 1,
  },
  totalView: { alignItems: 'flex-end', flex: 1, marginBottom: Platform.OS === 'ios' ? 1 : 2 },
  text: {
    fontSize: IMStyle.typography.fontSizeRegular + 4,
    alignSelf: 'flex-end',
  },
  totalText: {
    fontSize: IMStyle.typography.fontSizeSmall,
  },
  infoText: {
    marginLeft: 5,
    fontSize: IMStyle.typography.fontSizeRegular + 4,
  },
})
