import { IM, useLanguage } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { FlatList, Platform } from 'react-native'

import MoreCard from '../../../cards/MoreCard'
import PresenceTimeKeyCard from '../../../cards/PresenceTime/PresenceTimeKeyCard'
import IMRefreshControl from '../../../components/IMRefreshControl'
import NoEntry from '../../../components/NoEntry'
import { usePresenceTime } from '../../../contexts/PresenceTimeContext'
import useLayout from '../../../hooks/useLayout'

type Props = {
  loading: boolean
  setLoading: (b: boolean) => void
}

export default function PresenceTimesTabView({ loading, setLoading }: Props) {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout()
  const { activeTime, presenceTimeEntries, loading: loadingPresenceTime, load } = usePresenceTime()

  const [showUnOrderedState, setShowUnOrderedState] = useState(false)

  const noOrderExists = useMemo(() => !presenceTimeEntries.find(item => item.key.order), [presenceTimeEntries])
  const timesToShow = useMemo(
    () =>
      presenceTimeEntries.filter(
        time =>
          activeTime?.time.presenceTimeTypeId === time.key.id ||
          !!showUnOrderedState ||
          noOrderExists ||
          time.key.order ||
          !!time.times.find(timeTime => !!timeTime.duration)
      ),
    [presenceTimeEntries, activeTime, showUnOrderedState]
  )
  const showMoreButton = useMemo(
    () => !showUnOrderedState && !!presenceTimeEntries.length && timesToShow.length !== presenceTimeEntries.length,
    [showUnOrderedState, presenceTimeEntries]
  )

  return (
    <FlatList
      data={timesToShow}
      keyExtractor={item => item.key.id}
      ListFooterComponent={
        <IM.View>
          {showMoreButton && <MoreCard spacing={['horizontal', 'bottom']} onPress={() => setShowUnOrderedState(true)} />}
          {!loadingPresenceTime && !presenceTimeEntries.length && <NoEntry description={i18n.t('NO_PRESENCE_TIME_KEYS')} />}
        </IM.View>
      }
      renderItem={({ item }) => (
        <PresenceTimeKeyCard
          presenceTimeKey={item}
          spacing={!isSmallDevice && item.key.id === timesToShow[0].key.id ? 'all' : ['horizontal', 'bottom']}
        />
      )}
      refreshControl={
        Platform.OS !== 'web' ? (
          <IMRefreshControl
            refreshing={loading}
            onRefresh={() => {
              setLoading(true)
              load()
                .catch(console.error)
                .finally(() => setLoading(false))
            }}
          />
        ) : undefined
      }
    />
  )
}
