import { IM, useLanguage, useModalController } from '@infominds/react-native-components'
import { useFocusEffect } from '@react-navigation/native'
import React, { useCallback, useEffect, useState } from 'react'
import { Platform } from 'react-native'

import HomeHeader from '../../components/Headers/HomeHeader'
import IMRefreshControl from '../../components/IMRefreshControl'
import NewTimeButton from '../../components/NewTimeButton'
import { ScreenHeader } from '../../components/ScreenHeader'
import { useActivities } from '../../contexts/ActivitiesContext'
import { useEmployeeTime } from '../../contexts/EmployeeTimeContext'
import useLayout from '../../hooks/useLayout'
import NewTimeModal, { NewTimeModalData } from '../../modals/NewTimeModal'
import { HomeStackScreenProps } from '../../navigation/type'
import EmployeeTimeDetailedView from '../../views/Common/EmployeeTimeDetailedView'
import EmployeeTimeSummaryView from '../../views/History/EmployeeTimeSummaryView'
import HomeTimeSelectionView from '../../views/Home/HomeTimeSelectionView'

export default function HomeScreen({ navigation }: HomeStackScreenProps<'Home'>) {
  const { i18n } = useLanguage()
  const employeeTime = useEmployeeTime()
  const { activities } = useActivities()
  const newTimeController = useModalController<NewTimeModalData>()
  const { isSmallDevice } = useLayout()

  const [focus, setFocus] = useState(false)
  const [loading, setLoading] = useState(false)

  const stillLoading = (loading && !employeeTime.todaysTimes.length) || !activities.length
  const times = stillLoading || loading ? undefined : employeeTime.todaysTimes

  useFocusEffect(
    useCallback(() => {
      setFocus(prevState => !prevState)
    }, [])
  )

  useEffect(() => {
    !loading && setLoading(true)
  }, [focus])

  useEffect(() => {
    loading && loadTodayTimes()
  }, [loading])

  function loadTodayTimes() {
    const promises = [employeeTime.getCurrentTime(), employeeTime.updateTodaysTimes()]

    Promise.all(promises)
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  const handleUpdate = () => {
    setLoading(true)
    loadTodayTimes()
  }

  const handleNavigationToNewTime = () => {
    if (isSmallDevice) {
      navigation.navigate('NewTime')
    } else {
      newTimeController.show({})
    }
  }

  return (
    <IM.Screen
      screenHeader={<ScreenHeader barContent={<HomeHeader times={employeeTime.todaysTimes} onNewTimePress={handleNavigationToNewTime} />} />}>
      <IM.MasterDetail>
        <IM.MasterDetail.Master>
          <IM.ScrollView
            refreshControl={Platform.OS !== 'web' ? <IMRefreshControl refreshing={false} onRefresh={() => setLoading(true)} /> : undefined}
            spacing="horizontal">
            <HomeTimeSelectionView controller={newTimeController} />
            <IM.View spacing="vertical">
              <IM.View spacing="bottom">
                <IM.Text>{i18n.t('TODAY')}</IM.Text>
              </IM.View>
              <EmployeeTimeSummaryView times={times} error={false} loading={!times} disableEdit />
              {isSmallDevice && <EmployeeTimeDetailedView times={times} onUpdate={handleUpdate} onNavigateToNewTime={handleNavigationToNewTime} />}
            </IM.View>
          </IM.ScrollView>
        </IM.MasterDetail.Master>
        <IM.MasterDetail.Detail>
          <IM.ScrollView
            spacing="horizontal"
            refreshControl={Platform.OS !== 'web' ? <IMRefreshControl refreshing={false} onRefresh={() => setLoading(true)} /> : undefined}>
            <EmployeeTimeDetailedView times={times} onUpdate={handleUpdate} onNavigateToNewTime={handleNavigationToNewTime} spacing="vertical" />
          </IM.ScrollView>
        </IM.MasterDetail.Detail>
      </IM.MasterDetail>
      <NewTimeButton controller={newTimeController} visible={!employeeTime.isTimeActive && !!activities.length} />
      <NewTimeModal controller={newTimeController} />
    </IM.Screen>
  )
}
