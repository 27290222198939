import React from 'react'

import { InfoBoxStackScreenProps } from '../../navigation/type'
import InfoBoxAssetView from '../../views/InfoBox/InfoBoxAssetView'

const InfoBoxAssetScreen = ({ route }: InfoBoxStackScreenProps<'InfoboxNoBottomTabAsset'>) => {
  return <InfoBoxAssetView id={route.params.id} infoboxType={route.params.infoboxType} />
}

export default InfoBoxAssetScreen
