import { IM, IMLayout, IMStyle, useLanguage } from '@infominds/react-native-components'
import React, { createRef, useEffect, useState } from 'react'

import PresenceTimeTabs, { PresenceTimeTabItem } from '../../components/PresenceTimeTabs'
import Separator from '../../components/Separator'
import { usePresenceTime } from '../../contexts/PresenceTimeContext'
import useLayout from '../../hooks/useLayout'
import PresenceTimeInfoView from './PresenceTimeInfoView'
import AdditionalRepaymentsTabView from './tabs/AdditionalRepaymentsTabView'
import NotesTabView, { NotesTabViewRef } from './tabs/NotesTabView'
import PresenceTimesTabView from './tabs/PresenceTimesTabView'

type PresenceTimeViewProps = {
  forceSmallLayout?: boolean
  showAdditionalRepaymentsTab?: boolean
}

enum DisplayListType {
  PRESENCE_TIMES,
  ADDITIONAL_REPAYMENTS,
  NOTES,
}

export default function PresenceTimeView({ forceSmallLayout, showAdditionalRepaymentsTab }: PresenceTimeViewProps) {
  const { i18n } = useLanguage()
  const { numActiveAdditionalRepayments } = usePresenceTime()
  const { isSmallDevice, isLargeDevice } = useLayout(forceSmallLayout && 'small')
  const ref = createRef<NotesTabViewRef>()

  const [loading, setLoading] = useState(false)
  const [displayList, setDisplayList] = useState<DisplayListType>(DisplayListType.PRESENCE_TIMES)

  useEffect(() => {
    if (showAdditionalRepaymentsTab) setDisplayList(DisplayListType.ADDITIONAL_REPAYMENTS)
  }, [showAdditionalRepaymentsTab])

  const presenceTimeTabItems: PresenceTimeTabItem[] = [
    {
      name: i18n.t('TAB_PRESENCE_TIMES'),
      onClick: () => {
        if (ref.current === null || ref.current.checkUnsaved()) {
          setDisplayList(DisplayListType.PRESENCE_TIMES)
        }
      },
    },
    {
      name:
        numActiveAdditionalRepayments > 0
          ? `${i18n.t('TAB_ADDITIONAL_REPAYMENTS')} (${numActiveAdditionalRepayments})`
          : i18n.t('TAB_ADDITIONAL_REPAYMENTS'),
      onClick: () => {
        if (ref.current === null || ref.current.checkUnsaved()) {
          setDisplayList(DisplayListType.ADDITIONAL_REPAYMENTS)
        }
      },
    },
    {
      name: i18n.t('TAB_NOTES'),
      onClick: () => {
        setDisplayList(DisplayListType.NOTES)
      },
    },
  ]

  return (
    <IM.View style={IMStyle.layout.flex.f1}>
      <IM.View style={[IMLayout.flex.row, IMLayout.flex.f1]}>
        {isLargeDevice && (
          <>
            <IM.View style={IMLayout.flex.f3}>
              <PresenceTimeInfoView />
            </IM.View>
            <Separator type="vertical" />
          </>
        )}
        <IM.View style={IMLayout.flex.f6}>
          {isSmallDevice && <PresenceTimeInfoView forceSmallLayout={forceSmallLayout} />}
          <PresenceTimeTabs
            items={presenceTimeTabItems}
            position={displayList === DisplayListType.PRESENCE_TIMES ? 0 : displayList === DisplayListType.ADDITIONAL_REPAYMENTS ? 1 : 2}
          />
          <IM.View style={IMLayout.flex.f1}>
            {displayList === DisplayListType.PRESENCE_TIMES && <PresenceTimesTabView loading={loading} setLoading={setLoading} />}
            {displayList === DisplayListType.ADDITIONAL_REPAYMENTS && <AdditionalRepaymentsTabView loading={loading} setLoading={setLoading} />}
            {displayList === DisplayListType.NOTES && <NotesTabView ref={ref} loading={loading} setLoading={setLoading} />}
          </IM.View>
        </IM.View>
      </IM.View>
    </IM.View>
  )
}
