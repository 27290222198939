import { IM, IMLayout, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import DataBar from '../../components/DataBar'
import DurationDisplay from '../../components/DurationDisplay'
import ErrorDisplay from '../../components/ErrorDIsplay'
import SkeletonText from '../../components/Infominds/Skeleton/SkeletonText'
import { useHistory } from '../../contexts/HistoryContext'

export default function PresenceTimeActivitySummaryView() {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { activityTimes, minutesTarget, loading, error } = useHistory()

  const timeTarget = minutesTarget ?? 0
  const activityTimesSum = useMemo(() => Utils.sum(activityTimes, time => time.minutes ?? 0), [activityTimes])
  const activityTimesToShow = useMemo(() => activityTimes?.filter(t => !!t.minutes && t.minutes > 0) ?? [], [activityTimes])

  function getColorByIndex(index: number) {
    return theme.randomColors[index % theme.randomColors.length]
  }

  return (
    <IM.Card spacing="bottom">
      {loading ? (
        <SkeletonText />
      ) : (
        <>
          <IM.View style={IMLayout.flex.row} spacing={'bottom'}>
            <IM.View style={IMLayout.flex.f1}>
              <IM.Text primary>{i18n.t('ACTIVITY_TIMES')}</IM.Text>
            </IM.View>
          </IM.View>
          {(!activityTimes || !activityTimes?.length) && (
            <>
              {!!error && <ErrorDisplay error={i18n.t('FETCH_ACTIVITY_TIMES_ERROR')} />}
              {!error && <IM.Text>{i18n.t('NO_TIMES')}</IM.Text>}
            </>
          )}

          {!!activityTimes?.length && (
            <IM.View>
              <DurationDisplay name={i18n.t('TOTAL')} duration={activityTimesSum} formatTimeWithDimensions spacing={'bottom'} />

              <DataBar elements={activityTimesToShow} totalValue={timeTarget} elementDataProvider={activity => ({ value: activity.minutes ?? 0 })} />
              <IM.View spacing={'top'}>
                {activityTimes.map((activity, index) => (
                  <DurationDisplay
                    key={`PresenceTimeActivity${activity.activityTypeCode ?? ''}${activity.activityTypeId}`}
                    name={activity.activityTypeDescription?.trim() ?? ''}
                    color={getColorByIndex(index)}
                    duration={activity.minutes ?? 0}
                    formatTimeWithDimensions
                  />
                ))}
              </IM.View>
            </IM.View>
          )}
        </>
      )}
    </IM.Card>
  )
}
