import { useDimensions, useOrientation } from '@infominds/react-native-components'

export default function useLayout(forceLayout?: false | 'small' | 'medium' | 'large'): {
  isSmallDevice: boolean
  isLargeDevice: boolean
  isPortrait: boolean
} {
  const { isSmallDevice } = useDimensions()
  const { portrait } = useOrientation()

  if (forceLayout) {
    return {
      isSmallDevice: forceLayout === 'small',
      isLargeDevice: forceLayout === 'large',
      isPortrait: portrait,
    }
  }

  return {
    isSmallDevice,
    isLargeDevice: !isSmallDevice,
    isPortrait: portrait,
  }
}
