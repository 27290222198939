import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, useTheme } from '@infominds/react-native-components'
import { createDrawerNavigator, DrawerContentComponentProps, DrawerNavigationOptions } from '@react-navigation/drawer'
import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { BottomTabParamList } from '../navigation/type'
import { TabNavigationScreen } from '../types'

const Drawer = createDrawerNavigator()

const SETTINGS = {
  width: 55,
  iconSize: 25,
  webMarkerWidth: 4,
}

type Props = {
  tabs: TabNavigationScreen[]
  initialRouteName?: keyof BottomTabParamList
  screenOptions?: DrawerNavigationOptions
}

export default function TabNavigator({ tabs, initialRouteName, screenOptions }: Props) {
  const { theme } = useTheme()

  return (
    <Drawer.Navigator
      initialRouteName={initialRouteName}
      defaultStatus="open"
      drawerContent={TabBarProvider}
      screenOptions={{
        headerShown: false,
        drawerType: 'permanent',
        drawerStyle: { width: SETTINGS.width, borderRightColor: theme.input.border },
        ...screenOptions,
      }}>
      {tabs
        .filter(el => el.visible !== false)
        .map(tab => (
          <Drawer.Screen
            key={`BottomTabScreen${tab.name}`}
            name={tab.name}
            component={tab.component}
            options={{
              ...(tab.options as DrawerContentComponentProps),
              title: tab.title,
              drawerIcon: iconProps => TabBarIcon(iconProps, tab.icon),
            }}
          />
        ))}
    </Drawer.Navigator>
  )
}

function TabBarProvider(props: DrawerContentComponentProps) {
  return <TabBar {...props} />
}

function TabBar(tabProps: DrawerContentComponentProps) {
  const { theme } = useTheme()

  return (
    <View style={[styles.main, { backgroundColor: theme.tabNavigator.background }]}>
      <View style={styles.tabContainer}>
        {tabProps.state.routes.map((s, index) => (
          <TabContent key={`DrawerTab${index}`} routeKey={s.key} index={index} tabProps={tabProps} />
        ))}
      </View>
    </View>
  )
}

function TabBarIcon(iconProps: { focused: boolean; color: string; size: number }, tabIcon?: IconProp) {
  if (!tabIcon) return undefined
  return <IM.Icon style={styles.tabBarIcon} size={iconProps.size} icon={tabIcon} color={iconProps.color} />
}

function TabContent(props: { routeKey: string; index: number; tabProps: DrawerContentComponentProps }) {
  const { theme } = useTheme()
  const { state, descriptors, navigation } = props.tabProps
  const route = state.routes.find(r => r.key === props.routeKey)
  const descriptor = descriptors[props.routeKey]
  const isFocused = state.index === props.index

  const onPress = () => {
    const event = navigation.emit({
      type: 'drawerItemPress',
      target: props.routeKey,
      canPreventDefault: true,
    })

    if (!isFocused && !event.defaultPrevented && route) {
      navigation.navigate(route.name, route.params)
    }
  }

  return (
    <TouchableOpacity key={`Tab${props.routeKey}`} onPress={onPress} style={[styles.tab]}>
      {isFocused && (
        <>
          <View style={[styles.marker, { backgroundColor: theme.tabNavigator.focused.marker }]} />
          <View style={[styles.iconContainer]}>
            {!!descriptor?.options.drawerIcon &&
              descriptor?.options.drawerIcon({
                focused: isFocused,
                color: theme.tabNavigator.focused.icon ?? '',
                size: SETTINGS.iconSize,
              })}
          </View>
          <View style={styles.marker} />
        </>
      )}
      {!isFocused && (
        <View style={styles.iconContainer}>
          {!!descriptor?.options.drawerIcon &&
            descriptor?.options.drawerIcon({
              focused: isFocused,
              color: theme.tabNavigator.unFocused.icon ?? '',
              size: SETTINGS.iconSize,
            })}
        </View>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
  },
  tabContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tab: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  marker: {
    height: '75%',
    alignSelf: 'center',
    width: SETTINGS.webMarkerWidth,
  },
  iconContainer: {
    paddingVertical: 15,
    flex: 1,
  },
  icon: {
    borderWidth: 1,
    alignSelf: 'center',
  },
  tabBarIcon: {
    alignSelf: 'center',
  },
})
