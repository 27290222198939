import { IM, useTheme } from '@infominds/react-native-components'
import * as React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

interface Props {
  type?: 'horizontal' | 'vertical'
  style?: StyleProp<ViewStyle>
}

export default function Separator({ type = 'horizontal', style }: Props) {
  const { theme } = useTheme()

  return (
    <IM.View
      spacingType="margin"
      spacing={type === 'horizontal' ? 'vertical' : 'horizontal'}
      style={[
        type === 'horizontal' && styles.separatorHorizontal,
        type === 'vertical' && styles.separatorVertical,
        { borderBottomWidth: StyleSheet.hairlineWidth, borderColor: theme.input.border },
        style,
      ]}
    />
  )
}

const styles = StyleSheet.create({
  separatorVertical: {
    height: '100%',
    borderRadius: 100,
  },
  separatorHorizontal: {
    width: '100%',
    borderRadius: 100,
  },
})
