import type { ModalController } from '@infominds/react-native-components'
import React from 'react'
import { Platform } from 'react-native'

import type { Document } from '../apis/types/apiResponseTypes'
import Modal from '../components/Infominds/Modal'
import InfoBoxScreen from '../screens/InfoBox/InfoBoxScreen'

export type DocumentSelectionModalData = { onModalDocumentSelected?: (document: Document) => void }

type Props = {
  onSelected: (item: Document) => void
  controller: ModalController<void>
}

export default function DocumentSelectionModal({ onSelected, controller }: Props) {
  function handleDocumentSelection(document: Document) {
    onSelected(document)
    controller.close()
  }

  return (
    <Modal
      statusBarTranslucent
      isVisible={controller.isShown}
      onClose={controller.close}
      backdropOpacity={0}
      useNativeDriver={Platform.OS === 'android'}>
      <InfoBoxScreen controller={controller} route={{ key: '', name: 'Infobox' }} onModalDocumentSelected={handleDocumentSelection} />
    </Modal>
  )
}
