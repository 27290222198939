import { useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { RefreshControlProps, RefreshControl as RNRefreshControl, StyleSheet } from 'react-native'

export default function IMRefreshControl({ title, ...props }: RefreshControlProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()

  return (
    <RNRefreshControl
      {...props}
      title={title ?? i18n.t('PULL_TO_REFRESH')}
      tintColor={theme.text}
      titleColor={theme.text}
      style={styles.elem}
      progressBackgroundColor={theme.background}
      colors={theme.randomColors}
    />
  )
}

const styles = StyleSheet.create({
  elem: {
    backgroundColor: 'transparent',
  },
})
