import { IM, IMLayout, IMStyle, ModalController, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import Color from 'color'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FlatList, StyleSheet } from 'react-native'
import type Toast from 'react-native-toast-notifications'

import api from '../apis/apiCalls'
import { PresenceTimeDetail } from '../apis/types/apiResponseTypes'
import PresenceTimeKeyDetailCard from '../cards/PresenceTime/PresenceTimeKeyDetailCard'
import LoadingSpinnerModal from '../components/Infominds/LoadingSpinnerModal'
import Modal from '../components/Infominds/Modal'
import NoEntry from '../components/NoEntry'
import { ScreenHeader } from '../components/ScreenHeader'
import { usePresenceTime } from '../contexts/PresenceTimeContext'
import useUserSettings from '../hooks/useUserSettings'
import PresenceTimeUtils from '../utils/PresenceTimeUtils'
import TimeUtils from '../utils/TimeUtils'

export type PresenceTimeEditDetailModalControllerProps = {
  id: string
  addTime?: boolean
}

type PresenceTimeEditDetailsModalProps = {
  controller: ModalController<PresenceTimeEditDetailModalControllerProps>
}

type ModifyDetail = {
  detail: PresenceTimeDetail
  changed: boolean
  deleted: boolean
  isNewTime: boolean
}

export default function PresenceTimeEditDetailsModal({ controller }: PresenceTimeEditDetailsModalProps) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const toastRef = useRef<Toast | undefined>(undefined)
  const [busy, setBusy] = useState(false)
  const presenceTime = usePresenceTime()
  const { userSettings } = useUserSettings()
  const time = useMemo(
    () => presenceTime.presenceTimeEntries.find(pt => pt.key.id === controller.data?.id) ?? null,
    [presenceTime.presenceTimeEntries, controller.isShown]
  )

  const [details, setDetails] = useState<ModifyDetail[]>([])
  const detailsToShow = useMemo(() => details.filter(detail => !detail.deleted).sort((a, b) => (a.isNewTime && !b.isNewTime ? 1 : -1)), [details])
  const changesExist = useMemo(() => !!details.find(d => d.changed || d.deleted || d.isNewTime), [details])

  useEffect(() => {
    if (!controller.isShown) return
    const detailsToSet = PresenceTimeUtils.getTimeDetails(time?.times).map(detail => ({ detail, changed: false, deleted: false, isNewTime: false }))
    if (controller.data?.addTime) {
      detailsToSet.push(getNewDetail())
    }
    setDetails(detailsToSet)
  }, [controller.isShown])

  function handleDetailChanged(changedDetail: PresenceTimeDetail, deleted?: boolean) {
    setDetails(prev => {
      const foundDetail = prev.find(d => d.detail.id === changedDetail.id)
      if (!foundDetail) return prev
      foundDetail.changed = true
      if (deleted) foundDetail.deleted = true
      foundDetail.detail = { ...changedDetail }
      return [...prev]
    })
  }

  function onNewTimeButtonPressed() {
    setDetails(prev => {
      prev.push(getNewDetail())
      return [...prev]
    })
  }

  function getNewDetail() {
    return {
      detail: {
        id: Utils.getUid(),
        date: TimeUtils.getDateForRequest(),
        from: TimeUtils.getCurrentSeconds(),
        until: TimeUtils.getCurrentSeconds() + 60,
      },
      changed: false,
      deleted: false,
      isNewTime: true,
    } as ModifyDetail
  }

  function saveChanges() {
    setBusy(true)

    Promise.allSettled(
      details
        .filter(d => d.changed || d.deleted || d.isNewTime)
        .map(({ detail, deleted, isNewTime }) => {
          if (deleted) {
            return api.deletePresenceTime(undefined, { id: detail.id })
          }
          if (isNewTime) {
            return api.postPresenceTime({
              employeeId: userSettings?.employee.id ?? '',
              presenceTimeKeyId: time?.key.id ?? '',
              date: TimeUtils.getDateForRequest(presenceTime.date),
              from: detail.from,
              until: detail.until,
              duration: 0,
            })
          }
          return api.patchPresenceTime({ id: detail.id, from: detail.from, until: detail.until, duration: 0 })
        })
    )
      .catch(() => toastRef.current?.show(i18n.t('ERROR_PRESENCE_TIME_MODIFY'), { type: 'danger' }))
      .finally(() => {
        presenceTime
          .load()
          .catch(console.error)
          .finally(() => {
            setBusy(false)
            controller.close()
          })
      })
  }

  return (
    <Modal
      statusBarTranslucent
      isVisible={controller.isShown}
      onClose={controller.close}
      toastRef={toastRef}
      screenHeader={
        <ScreenHeader
          goBack={controller.close}
          closeIcon={['fal', 'check']}
          close={changesExist ? saveChanges : undefined}
          title={`${i18n.t('TAB_PRESENCE_TIMES')} - ${time?.key.code ?? ''}`}
        />
      }>
      <IM.View style={IMLayout.flex.f1} spacing={'top'}>
        <FlatList
          data={detailsToShow}
          renderItem={({ item, index }) => (
            <>
              {!!item.isNewTime && (index === 0 || !detailsToShow[index - 1].isNewTime) && (
                <IM.View spacing={['all', 'top']} style={styles.newTimesTextView}>
                  <IM.Text>{i18n.t('NEW_TIMES')}</IM.Text>
                </IM.View>
              )}
              <PresenceTimeKeyDetailCard
                detail={item.detail}
                spacing={['horizontal', 'bottom']}
                updateDetail={handleDetailChanged}
                deleteDetail={() => handleDetailChanged(item.detail, true)}
                toastRef={toastRef}
              />
            </>
          )}
          ListFooterComponent={<IM.View>{!detailsToShow.length && <NoEntry description={i18n.t('NO_TIMES')} />}</IM.View>}
        />
      </IM.View>
      <IM.PressableIcon
        icon={['fal', 'plus']}
        size={30}
        onPress={onNewTimeButtonPressed}
        color={IMStyle.palette.white}
        opacityColor={Color(theme.button.pressedOpacity).alpha(0.5).toString()}
        style={[styles.button, { backgroundColor: theme.card.headBackground }]}
      />
      <LoadingSpinnerModal isVisible={busy} />
    </Modal>
  )
}

const styles = StyleSheet.create({
  button: {
    position: 'absolute',
    bottom: 9,
    right: 9,
    zIndex: 10,
  },
  newTimesTextView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
})
