import { IM } from '@infominds/react-native-components'
import React from 'react'
import { Platform, StatusBar, StyleSheet } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { InfoBoxStackScreenProps } from '../../navigation/type'
import InfoBoxCameraView from '../../views/InfoBox/InfoBoxCameraView'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
})

const InfoBoxCameraScreen = ({}: InfoBoxStackScreenProps<'InfoboxNoBottomTabCamera'>) => {
  const { top, bottom } = useSafeAreaInsets()

  return (
    <IM.View
      style={[
        {
          marginTop: Platform.OS === 'ios' ? undefined : top,
          marginBottom: Platform.OS === 'ios' ? undefined : bottom,
        },
        styles.container,
      ]}>
      <StatusBar backgroundColor="#000000" barStyle="dark-content" translucent />
      <InfoBoxCameraView />
    </IM.View>
  )
}

export default InfoBoxCameraScreen
