import { IM, IMStyle, Spacing, useAlert, useLanguage, useModalController, useTheme, Utils } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import type { EmployeeTime } from '../../apis/types/apiResponseTypes'
import NoEntry from '../../components/NoEntry'
import { useToast } from '../../contexts/ToastReferenceContext'
import useAllowEmployeeTimeEdit from '../../hooks/useAllowEmployeeTimeEdit'
import useLayout from '../../hooks/useLayout'
import ModifyTimeModal, { ModifyTimeData } from '../../modals/ModifyTimeModal'
import TimeUtils, { TimeFormat } from '../../utils/TimeUtils'
import appUtils from '../../utils/Utils'

interface EmployeeTimeDetailedViewProps {
  times: EmployeeTime[] | undefined
  spacing?: Spacing
  onUpdate: () => void
  onNavigateToNewTime: () => void
}

const ICON_DIMENSION = 30

export default function EmployeeTimeDetailedView({ times, spacing, onUpdate, onNavigateToNewTime }: EmployeeTimeDetailedViewProps) {
  const toast = useToast()
  const alert = useAlert()
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const { isSmallDevice } = useLayout()
  const modifyTimeController = useModalController<ModifyTimeData>()
  const { allowEditOfDate } = useAllowEmployeeTimeEdit()

  const entries = times?.sort((a, b) => a.from - b.from)

  const iconDimensions = {
    height: ICON_DIMENSION,
    width: ICON_DIMENSION,
  }

  const lineColor = {
    borderColor: theme.textDetail,
  }

  function handlerCardPress(employeeTime: EmployeeTime, isRecording: boolean) {
    if (isRecording) {
      onNavigateToNewTime()
    } else {
      !modifyTimeController.isShown && modifyTimeController.show({ employeeTime })
    }
  }

  async function handleDelete(deleteId: string, activityName: string, from: number, to: number) {
    const sub = times?.find(elem => elem.id === deleteId)?.subordinates

    const message = Utils.stringValueReplacer(
      sub ? i18n.t('DELETE_TOAST_MESSAGE_SUB') : i18n.t('DELETE_TOAST_MESSAGE'),
      activityName,
      TimeUtils.secondsToTime(from, TimeFormat.TIME),
      TimeUtils.secondsToTime(to, TimeFormat.TIME)
    )

    const alertAnswer = await appUtils.changeTimeAlert(i18n, alert, i18n.t('DELETE_TOAST_MESSAGE_TITLE'), message, sub)

    if (alertAnswer === 'all' || alertAnswer === 'mine') {
      api
        .deleteEmployeeTime(undefined, { id: deleteId, changeAssociatedTimes: alertAnswer === 'all' })
        .then(() => onUpdate())
        .catch(err => {
          console.error(`Failed deleting time id ${deleteId}`, err)
          toast.show(
            Utils.stringValueReplacer(
              i18n.t('DELETE_EMPLOYEE_TIME_ERROR'),
              TimeUtils.secondsToTime(from, TimeFormat.TIME),
              TimeUtils.secondsToTime(to, TimeFormat.TIME)
            ),
            { type: 'danger' }
          )
        })
    }
  }

  return (
    <>
      {entries?.length !== undefined && entries.length !== 0 && (
        <IM.Card spacing={spacing}>
          {entries.map((elem, index) => {
            const recordStopped = elem.from <= elem.until

            return (
              <IM.View key={`${elem.id}-${elem.from}-${elem.until}`} spacing={index !== entries.length - 1 ? 'bottom' : 'none'}>
                <IM.View style={styles.time}>
                  <IM.View style={styles.startText}>
                    <IM.View spacing="right">
                      <IM.Icon icon={['fal', 'play']} style={[styles.icon, styles.startIcon, iconDimensions]} color={IMStyle.palette.white} />
                    </IM.View>
                    <IM.Text>{TimeUtils.secondsToTime(elem.from, TimeFormat.TIME)}</IM.Text>
                  </IM.View>
                  <IM.View>
                    {recordStopped && allowEditOfDate(elem.date) && (
                      <IM.PressableIcon
                        icon={['fal', 'trash']}
                        size={17}
                        onPress={() => {
                          handleDelete(elem.id, elem.employeeTimeType, elem.from, elem.until).catch(err => console.error('Failed handleDelete', err))
                        }}
                      />
                    )}
                  </IM.View>
                </IM.View>
                <IM.View spacing="vertical" style={[styles.line, lineColor, { marginLeft: ICON_DIMENSION / 2 }]}>
                  <IM.Card
                    style={{
                      marginLeft: ICON_DIMENSION / 2 + IMStyle.layout.horizontalMargin,
                      backgroundColor: theme.card.secondaryBackground,
                    }}
                    defaultContent={{
                      texts: [
                        {
                          text: elem.employeeTimeType,
                          primary: true,
                          icon: elem.subordinates ? ['fal', 'users'] : undefined,
                          alignIcon: 'right',
                          numberOfLines: 1,
                        },
                        !!elem.description && {
                          text: elem.description,
                          primary: true,
                          numberOfLines: 1,
                        },
                        {
                          text: elem.document ?? i18n.t('NO_DOCUMENT'),
                          secondary: true,
                          numberOfLines: 1,
                          style: { marginTop: IMStyle.layout.verticalMargin },
                        },
                        !!elem.customer && {
                          text: elem.customer + ' (' + elem.customerNumber.toString() + ')',
                          secondary: true,
                          numberOfLines: 1,
                        },
                        !!elem.note && { text: elem.note, secondary: true, numberOfLines: 1, style: { marginTop: IMStyle.layout.verticalMargin } },
                      ],
                      endAdornment: { text: TimeUtils.minutesToTime(elem.duration, TimeFormat.TIME_WITH_DIMENSIONS) },
                      style: { alignItems: 'center' },
                    }}
                    onPress={() => handlerCardPress(elem, !recordStopped)}
                  />
                </IM.View>
                <IM.View style={[styles.time, styles.stopText]} spacing={index !== entries.length - 1 ? 'bottom' : 'none'}>
                  <IM.View spacing="right">
                    <IM.Icon icon={['fal', 'stop']} style={[styles.icon, styles.stopIcon, iconDimensions]} color={IMStyle.palette.white} />
                  </IM.View>
                  {elem.until > elem.from && <IM.Text>{TimeUtils.secondsToTime(elem.until, TimeFormat.TIME)}</IM.Text>}
                </IM.View>
                {index !== entries.length - 1 && (
                  <IM.View style={styles.dashedLineContainer}>
                    <IM.View style={[styles.dashedLine, styles.line, lineColor]} />
                  </IM.View>
                )}
              </IM.View>
            )
          })}
        </IM.Card>
      )}
      {entries?.length === 0 && !isSmallDevice && <NoEntry description={i18n.t('NO_TIMES')} />}
      <ModifyTimeModal controller={modifyTimeController} onTimeModified={onUpdate} />
    </>
  )
}
const styles = StyleSheet.create({
  line: {
    borderLeftWidth: 1,
  },
  dashedLine: {
    height: 30,
    borderStyle: 'dashed',
    borderWidth: 1,
  },
  dashedLineContainer: {
    height: 30,
    width: 1,
    marginLeft: ICON_DIMENSION / 2,
    marginTop: -15,
    marginBottom: -15,
    zIndex: -1,
    overflow: 'hidden',
  },
  icon: {
    borderRadius: IMStyle.layout.iconRadius,
    alignItems: 'center',
    justifyContent: 'center',
  },
  startIcon: {
    backgroundColor: '#25D07C',
    paddingLeft: 3,
  },
  startText: { flexDirection: 'row', alignItems: 'center' },
  stopIcon: {
    backgroundColor: '#FF5858',
  },
  stopText: {
    justifyContent: 'flex-start',
  },
  time: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})
