import { IM, IMLayout, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'

import api from '../apis/apiCalls'
import { Company } from '../apis/types/apiResponseTypes'
import useLayout from '../hooks/useLayout'
import { ThemeColorExpanded } from '../types'
import DropdownArrowDown from './DropdownArrowDown'
import DropdownArrowUp from './DropdownArrowUp'
import DropdownCheck from './DropdownCheck'

export type CompanySelectorRef = { close: () => void }

interface Props {
  onOpen: () => void
  onReLogIn: () => void
}

const CompanySelector = ({ onOpen, onReLogIn }: Props, ref: ForwardedRef<CompanySelectorRef>) => {
  useImperativeHandle(ref, () => ({
    close: () => setOpen(false),
  }))

  const { alert } = useAlert()
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { companies: licenseCompanies, company: selectedCompany, updateToken } = useAuthentication()
  const { isPortrait, isSmallDevice } = useLayout()
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)
  const [companies, setCompanies] = useState<Company[]>([])
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>(undefined)

  const originalCompanyId = useRef<string | undefined>(undefined)

  useEffect(() => {
    api
      .getCompanies()
      .then(gotCompanies => {
        const filteredArray = gotCompanies.filter(value => licenseCompanies?.includes(value.id))
        setCompanies(filteredArray)

        if (filteredArray.length > 0 && filteredArray.find(el => el.id === selectedCompany)) {
          originalCompanyId.current = selectedCompany
          setSelectedCompanyId(selectedCompany)
        } else {
          console.error('Empty company array loaded from db')
        }
      })
      .catch(console.error)
  }, [licenseCompanies, selectedCompany])

  useEffect(() => {
    if (selectedCompanyId !== undefined) {
      const newCompany = companies.find(item => item.id === selectedCompanyId)

      if (newCompany) {
        if (originalCompanyId.current !== newCompany.id) {
          alert(i18n.t('WARNING'), i18n.t('CHANGE_COMPANY_WARNING'), [
            {
              text: i18n.t('CANCEL'),
              style: 'default',
              onPress: () => {
                return setSelectedCompanyId(originalCompanyId.current)
              },
            },
            {
              text: i18n.t('RESTART_APP'),
              style: 'destructive',
              onPress: () => {
                updateToken(newCompany.id, undefined)
                  .then(() => onReLogIn())
                  .catch(err => console.error('Failed company re-auth:', err))
              },
            },
          ])
        }
      } else {
        console.error(`Company ${selectedCompanyId ?? ''} not found`)
        setError(true)
      }
    }
  }, [selectedCompanyId])

  const onCompanyOpen = useCallback(() => {
    onOpen()
  }, [])

  const companyItems = useMemo(
    () =>
      companies.map(company => {
        return { label: company.description, value: company.id }
      }),
    [companies]
  )

  const common = {
    items: companyItems,
    loading: companyItems.length === 0,
    containerStyle: { width: isSmallDevice ? 240 : isPortrait ? 260 : 300 },
    setOpen: setOpen,
    setValue: setSelectedCompanyId,
    TickIconComponent: DropdownCheck,
    ArrowDownIconComponent: DropdownArrowDown,
    ArrowUpIconComponent: DropdownArrowUp,
  }

  return (
    <IM.View>
      {selectedCompanyId && error === false ? (
        <IM.Dropdown
          closeAfterSelecting
          placeholder={i18n.t('LOADING_PLACEHOLDER')}
          open={open}
          value={selectedCompanyId}
          onOpen={onCompanyOpen}
          listMode="SCROLLVIEW"
          {...common}
          style={[styles.height, { backgroundColor: theme.dropdown.background }]}
          containerStyle={styles.dropdown}
          // eslint-disable-next-line react-native/no-inline-styles
          dropDownContainerStyle={{ backgroundColor: theme.dropdown.background, borderColor: 'transparent' }}
        />
      ) : (
        <IM.Dropdown
          placeholder={error ? i18n.t('ERROR_PLACEHOLDER') : i18n.t('LOADING_PLACEHOLDER')}
          open={false}
          value={null}
          disabled
          listMode="SCROLLVIEW"
          {...common}
          style={[styles.height, { backgroundColor: theme.dropdown.background }]}
          containerStyle={styles.dropdown}
          // eslint-disable-next-line react-native/no-inline-styles
          dropDownContainerStyle={{ backgroundColor: theme.dropdown.background, borderColor: 'transparent' }}
        />
      )}
    </IM.View>
  )
}

export default forwardRef(CompanySelector)

const styles = StyleSheet.create({
  dropdown: { width: 220, borderRadius: IMLayout.borderRadius },
  height: { minHeight: 42 },
})
