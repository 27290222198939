import { IM, SpacingProps } from '@infominds/react-native-components'
import isEqual from 'lodash/isEqual'
import React from 'react'

import DataBar from './DataBar'
import DurationDisplay from './DurationDisplay'

interface TimeBarProps {
  elements: HistoryElement[]
  title: string
  time: number
  color?: string
  spacing?: SpacingProps
}

export type HistoryElement = {
  id: string
  name: string
  duration: number
  color: string
  ratio: number
  displayRatio: number
}

function arePropsEqual(prevProps: TimeBarProps, nextProps: TimeBarProps) {
  const { elements: prevElements, ...prevOther } = prevProps
  const { elements: nextElements, ...nextOther } = nextProps

  return prevElements.length === nextElements.length && isEqual(prevOther, nextOther)
}

export const TimeBar = ({ elements, title, time, color, spacing }: TimeBarProps) => {
  return (
    <IM.View spacing={spacing}>
      <DurationDisplay name={title} duration={time} color={color} spacing={elements.length === 0 ? 'none' : 'bottom'} formatTimeWithDimensions />
      <DataBar elements={elements} elementDataProvider={element => ({ value: element.ratio * 100, color: element.color })} />
    </IM.View>
  )
}

export default React.memo(TimeBar, arePropsEqual)
