import { IM, IMLayout, Language, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../types'
import DropdownArrowDown from './DropdownArrowDown'
import DropdownArrowUp from './DropdownArrowUp'
import DropdownCheck from './DropdownCheck'

export type LanguageSelectorRef = { close: () => void }

const languages: { label: string; value: Language }[] = [
  { label: 'Deutsch', value: 'de' },
  { label: 'Italiano', value: 'it' },
  { label: 'English', value: 'en' },
]

interface Props {
  onOpen: () => void
  onReLogIn: () => void
}

const LanguageSelector = ({ onOpen, onReLogIn }: Props, ref: ForwardedRef<LanguageSelectorRef>) => {
  useImperativeHandle(ref, () => ({
    close: () => setOpen(false),
  }))

  const { alert } = useAlert()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { updateToken } = useAuthentication()
  const { i18n, language, setLanguageAsync } = useLanguage()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(language)

  const originalLanguage = useRef(language)

  useEffect(() => {
    if (originalLanguage.current !== selected) {
      alert(i18n.t('WARNING'), i18n.t('CHANGE_LANGUAGE_WARNING'), [
        {
          text: i18n.t('CANCEL'),
          style: 'default',
          onPress: () => {
            return setSelected(originalLanguage.current)
          },
        },
        {
          text: i18n.t('RESTART_APP'),
          style: 'destructive',
          onPress: () => {
            setLanguageAsync(selected)
              .then(() => {
                updateToken(undefined, selected)
                  .then(() => {
                    setSelected(selected)
                    originalLanguage.current = selected
                    onReLogIn()
                  })
                  .catch(err => console.error('Failed language re-auth:', err))
              })
              .catch(console.error)
          },
        },
      ])
    }
  }, [selected])

  return (
    <IM.Dropdown
      closeAfterSelecting
      open={open}
      value={selected}
      items={languages}
      setOpen={setOpen}
      setValue={setSelected}
      onChangeValue={val => {
        val !== null && setSelected(val)
      }}
      listMode="SCROLLVIEW"
      style={[styles.height, { backgroundColor: theme.dropdown.background }]}
      containerStyle={styles.dropdown}
      // eslint-disable-next-line react-native/no-inline-styles
      dropDownContainerStyle={{ backgroundColor: theme.dropdown.background, borderColor: 'transparent' }}
      TickIconComponent={DropdownCheck}
      ArrowDownIconComponent={DropdownArrowDown}
      ArrowUpIconComponent={DropdownArrowUp}
      onOpen={onOpen}
    />
  )
}

export default forwardRef(LanguageSelector)

const styles = StyleSheet.create({
  dropdown: { width: 220, borderRadius: IMLayout.borderRadius },
  height: { minHeight: 42 },
})
