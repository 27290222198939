import InfomindsAnalytics from '@infominds/react-native-analytics'
import { IM, IMLayout, IMStyle } from '@infominds/react-native-components'
import { useStorageMigration } from '@infominds/react-native-license'
import React, { useEffect } from 'react'
import { ActivityIndicator, LogBox, Platform, StatusBar, View } from 'react-native'
import Orientation from 'react-native-orientation-locker'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { ToastProvider } from 'react-native-toast-notifications'
import { RecoilEnv, RecoilRoot } from 'recoil'

import { languageResources } from './assets/languages/LanguageResources'
import AuthenticationWrapper from './components/AuthenticationWrapper'
import AppContent from './components/Infominds/AppContent'
import { CalendarProvider } from './components/Infominds/Calendar/context/CalendarContext'
import { CONSTANTS } from './constants/Constants'
import { firebaseConfig } from './constants/FirebaseConfigWeb'
import { ActivitiesContextProvider } from './contexts/ActivitiesContext'
import { UserSettingsProvider } from './contexts/UserSettingsContext'
import useLayout from './hooks/useLayout'
import InitStructures, { themeExpansion } from './InitStructures'
import Navigator from './navigation/Navigator'

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = !__DEV__

export default function App() {
  const migrated = useStorageMigration({ isExpensesApp: false })
  const { isSmallDevice } = useLayout()

  useEffect(() => {
    InfomindsAnalytics.init(firebaseConfig)

    if (Platform.OS !== 'web') {
      LogBox.ignoreAllLogs()
    }
  }, [])

  useEffect(() => {
    isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios') && Orientation.lockToPortrait()
  }, [isSmallDevice])

  if (migrated === undefined) return <></>
  if (migrated === false) console.error('🛑 Migration failed!')

  return (
    <RecoilRoot>
      <InitStructures />
      <CalendarProvider>
        <IM.LanguageProvider resources={languageResources}>
          <IM.LanguageContext.Consumer>
            {languageContext => {
              // IM.View does not work here
              if (!languageContext?.initOk) {
                return (
                  <>
                    {Platform.OS !== 'web' ? (
                      <IM.LoadingSpinner isVisible />
                    ) : (
                      // eslint-disable-next-line react-native/no-inline-styles
                      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <ActivityIndicator size="large" color={IMStyle.palette.tint} />
                      </View>
                    )}
                  </>
                )
              }

              return (
                <IM.ThemeProvider theme={themeExpansion}>
                  <IM.ThemeContext.Consumer>
                    {themeContext => (
                      <AppContent>
                        <ToastProvider
                          placement="bottom"
                          duration={CONSTANTS.toastVisibilityTime}
                          offsetBottom={IMStyle.layout.verticalMargin * (Platform.OS === 'ios' ? 4 : 2)}
                          offset={20}
                          swipeEnabled={true}>
                          <IM.AlertProvider>
                            <ActivitiesContextProvider>
                              <AuthenticationWrapper>
                                {({ authReady, isLoggedIn }) => (
                                  <UserSettingsProvider>
                                    {({ loading }) => (
                                      <SafeAreaProvider>
                                        <StatusBar
                                          translucent
                                          backgroundColor="transparent"
                                          barStyle={themeContext?.colorScheme === 'light' ? 'dark-content' : 'light-content'}
                                        />
                                        {authReady && loading === false ? (
                                          <Navigator isLoggedIn={isLoggedIn} />
                                        ) : (
                                          // todo: lento nel web al refresh perchè ogni volta chiama ios api.
                                          <IM.View style={[IMLayout.flex.f1, { backgroundColor: themeContext?.theme.background }]}>
                                            {Platform.OS !== 'web' && <IM.LoadingSpinner isVisible />}
                                          </IM.View>
                                        )}
                                      </SafeAreaProvider>
                                    )}
                                  </UserSettingsProvider>
                                )}
                              </AuthenticationWrapper>
                            </ActivitiesContextProvider>
                          </IM.AlertProvider>
                        </ToastProvider>
                      </AppContent>
                    )}
                  </IM.ThemeContext.Consumer>
                </IM.ThemeProvider>
              )
            }}
          </IM.LanguageContext.Consumer>
        </IM.LanguageProvider>
      </CalendarProvider>
    </RecoilRoot>
  )
}
