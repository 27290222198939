import { useAuthentication } from '@infominds/react-native-license'
import { useRecoilState } from 'recoil'

import type { LastUsedTimeData } from '../types'
import { lastUsedTimesAtom } from '../utils/stateManager'

export default function useLastUsedTimes(maxItemCount?: number) {
  const { sessionKey } = useAuthentication()
  const [lastUsed, setLastUsed] = useRecoilState(lastUsedTimesAtom(sessionKey))
  const maxItemsToBeStored = maxItemCount ?? 3

  function compareItems(a: LastUsedTimeData, b: LastUsedTimeData) {
    return a.activity.id === b.activity.id && a.document?.id === b.document?.id
  }

  function add(item: LastUsedTimeData) {
    if (!item) return undefined
    try {
      let newItems: LastUsedTimeData[] = []
      setLastUsed(prev => {
        //remove item if it already is in list.
        prev = prev.filter(lastUsedItem => !compareItems(lastUsedItem, item))
        //add new item on pos 0 in list
        newItems = [item, ...prev].slice(0, maxItemsToBeStored)
        return newItems
      })
      return newItems
    } catch (e) {
      console.error(e)
    }
    return undefined
  }

  function removeItem(item: LastUsedTimeData) {
    if (!item || !lastUsed?.length) return
    try {
      setLastUsed(prev => [...prev.filter(p => p !== item)])
    } catch (e) {
      console.error(e)
    }
  }

  function updateItem(item: LastUsedTimeData, newItemData: LastUsedTimeData) {
    if (!item) return
    try {
      setLastUsed(prev => [...prev.map(p => (p === item ? newItemData : p))])
    } catch (e) {
      console.error(e)
    }
  }

  return {
    add,
    items: lastUsed,
    updateItem,
    removeItem,
  }
}
