import { ClientProvider } from '@infominds/react-api'
import { mediaUtils } from '@infominds/react-native-media'

import type {
  DeleteEmployeeTimeRequest,
  DeleteInfoboxFileRequest,
  DeletePresenceTimeRequest,
  GetAdditionalRepaymentKeyRequest,
  GetAdditionalRepaymentRequest,
  GetDocumentsRequest,
  GetEmployeeRequest,
  GetEmployeeTimeRequest,
  GetEmployeeTimeTypes,
  GetInfoboxFilesRequest,
  GetInfoboxFolderRequest,
  GetPresenceTimeKeysRequest,
  GetPresenceTimeOfDayRequest,
  GetPresenceTimesRequest,
  PatchEmployeeTimeRequest,
  PatchPresenceTimeRequest,
  PostAdditionalRepaymentRequest,
  PostEmployeeTimeRequest,
  PostInfoboxFileRequest,
  PostPresenceTimeRequest,
  PostStopEmployeeTimeRequest,
  StopPresenceTimeRequest,
} from './types/apiRequestTypes'
import type {
  AdditionalRepayment,
  AdditionalRepaymentKey,
  Company,
  Document,
  EmployeeTime,
  EmployeeTimeType,
  File,
  Folder,
  HighResFile,
  PostFile,
  PresenceTime,
  PresenceTimeKey,
  PresenceTimeOfDay,
  UserSettings,
} from './types/apiResponseTypes'

const document = ClientProvider<Document[], GetDocumentsRequest>('document')
const infobox = ClientProvider<unknown, unknown, PostFile, PostInfoboxFileRequest, unknown, unknown, boolean, DeleteInfoboxFileRequest>('infobox')
const infoboxFolders = ClientProvider<Folder[], GetInfoboxFolderRequest>('infobox/infoboxfolder')
const infoboxFile = ClientProvider<HighResFile, GetInfoboxFilesRequest>('infobox/infoboxfile')
const infoboxFiles = ClientProvider<File[], GetInfoboxFilesRequest>('infobox/infoboxfiles', fetchedFiles => {
  return fetchedFiles.filter(
    elem =>
      mediaUtils.isAudio(elem.extension) ||
      mediaUtils.isVideo(elem.extension) ||
      mediaUtils.isImage(elem.extension) ||
      mediaUtils.isPdf(elem.extension)
  )
})
const employeeTime = ClientProvider<
  EmployeeTime[],
  GetEmployeeTimeRequest,
  string,
  PostEmployeeTimeRequest,
  string,
  PatchEmployeeTimeRequest,
  string,
  DeleteEmployeeTimeRequest
>('employeeTime')
const presenceTime = ClientProvider<
  unknown,
  unknown,
  string,
  PostPresenceTimeRequest,
  string,
  PatchPresenceTimeRequest,
  string,
  DeletePresenceTimeRequest
>('presenceTime/presenceTime')
const presenceTimeOfDay = ClientProvider<
  PresenceTimeOfDay,
  GetPresenceTimeOfDayRequest,
  undefined,
  undefined,
  string,
  Pick<PresenceTimeOfDay, 'date' | 'annotation'> & { employeeId: string }
>('presenceTime/presenceTimeOfDay')

const api = {
  getDocuments: document.GET,

  //infobox
  getInfoboxFolders: infoboxFolders.GET,
  deleteInfoboxFile: infobox.DELETE,
  postInfoboxFile: infobox.POST,

  getInfoboxFiles: infoboxFiles.GET,
  getInfoboxFile: infoboxFile.GET,

  //company
  getCompanies: ClientProvider<Company[]>('company').GET,
  //employee
  getEmployee: ClientProvider<Company[], GetEmployeeRequest>('employee').GET,
  //employeeTime
  getEmployeeTime: employeeTime.GET,
  postEmployeeTime: employeeTime.POST,
  patchEmployeeTime: employeeTime.PATCH,
  deleteEmployeeTime: employeeTime.DELETE,
  stopEmployeeTime: ClientProvider<unknown, unknown, string, PostStopEmployeeTimeRequest>('employeeTime/stop').POST,
  getEmployeeTimeTypes: ClientProvider<EmployeeTimeType[], GetEmployeeTimeTypes>('employeeTime/types').GET,
  //presenceTime
  getPresenceTimeKeys: ClientProvider<PresenceTimeKey[], GetPresenceTimeKeysRequest>('presenceTime/presenceTimeKeys').GET,
  getAdditionalRepaymentKeys: ClientProvider<AdditionalRepaymentKey[], GetAdditionalRepaymentKeyRequest>(
    'presenceTime/presenceTimeAdditionalRepaymentKeys'
  ).GET,
  getAdditionalRepayment: ClientProvider<AdditionalRepayment[], GetAdditionalRepaymentRequest>('presenceTime/presenceTimeAdditionalRepayments').GET,
  getPresenceTimes: ClientProvider<PresenceTime[], GetPresenceTimesRequest>('presenceTime/presenceTimes').GET,
  getPresenceTimeOfDay: presenceTimeOfDay.GET,
  editPresenceTimeOfDay: presenceTimeOfDay.PATCH,
  postPresenceTime: presenceTime.POST,
  patchPresenceTime: presenceTime.PATCH,
  deletePresenceTime: presenceTime.DELETE,
  stopPresenceTime: ClientProvider<unknown, unknown, string, StopPresenceTimeRequest>('presenceTime/presenceTimeStop').POST,
  postAdditionalRepayment: ClientProvider<unknown, unknown, string, PostAdditionalRepaymentRequest>('presenceTime/presenceTimeAdditionalRepayment')
    .POST,
  deleteAdditionalRepayment: (id: string) =>
    ClientProvider<unknown, unknown, unknown, unknown, unknown, unknown, boolean>(`presenceTime/presenceTimeAdditionalRepayment/${id}`).DELETE(),
  //userSettings
  getUserSettings: ClientProvider<UserSettings>('userSettings').GET,
}

export default api
