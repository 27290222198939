import { IM, useTheme } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { Platform, StyleSheet } from 'react-native'

export default function AppContent({ children }: PropsWithChildren) {
  const { theme, colorScheme } = useTheme()

  const webScrollbarColorScheme = Platform.select({ web: { colorScheme: colorScheme === 'dark' ? 'dark' : 'light' } })

  // @ts-ignore webScrollbarColorScheme (this is working on web even if it will give error)
  return <IM.View style={[styles.screen, { backgroundColor: theme.background }, webScrollbarColorScheme]}>{children}</IM.View>
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: 'center',
    // @ts-ignore: todo
    colorScheme: 'dark',
  },
})
