import { IM } from '@infominds/react-native-components'
import React from 'react'

import SettingsHeader from '../../components/Headers/SettingsHeader'
import { ScreenHeader } from '../../components/ScreenHeader'
import SettingsView from '../../views/Settings/SettingsView'

export default function SettingsScreen() {
  return (
    <IM.Screen screenHeader={<ScreenHeader barContent={<SettingsHeader />} />}>
      <SettingsView />
    </IM.Screen>
  )
}
