import { Utils } from '@infominds/react-native-components'
import { getDay } from 'date-fns'

import api from '../apis/apiCalls'
import { ActivityTime, PresenceTime, PresenceTimeKey, PresenceTimeKeyType } from '../apis/types/apiResponseTypes'
import { ActivePresenceTime, AdditionalRepaymentEntry, PresenceTimeEntry } from '../types'
import TimeUtils from './TimeUtils'

const PresenceTimeUtils = {
  async getPresenceTimeEntries(employeeId: string, date?: Date) {
    const [keys, timeOfDay, additionalRepaymentKeys] = await Promise.all([
      api.getPresenceTimeKeys({}),
      api.getPresenceTimeOfDay({ employeeId: employeeId, date: TimeUtils.getDateForRequest(date) }),
      api.getAdditionalRepaymentKeys({}),
    ])
    return {
      info: timeOfDay,
      keys: keys.map<PresenceTimeEntry>(key => ({ key, times: timeOfDay.presenceTimes?.filter(pt => pt.presenceTimeTypeId === key.id) ?? [] })),
      additionalRepaymentEntries: additionalRepaymentKeys.map<AdditionalRepaymentEntry>(key => ({
        key,
        additionalRepayment:
          timeOfDay.presenceTimeAdditionalRepayments?.filter(ap => ap.presenceTimeAdditionalRepaymentKeyId === key.id)[0] ?? undefined,
      })),
    }
  },
  createPresenceTime(employeeId: string, presenceTimeKeyId: string, from?: number, until?: number, duration?: number, date?: Date) {
    const request = {
      employeeId,
      presenceTimeKeyId,
      from: Math.max(from ?? 0, 1),
      until: until,
      duration,
      date: TimeUtils.getDateForRequest(date),
    }
    return api.postPresenceTime(request)
  },
  createAdditionalRepayment(employeeId: string, additionalRepaymentKeyId: string, date?: Date) {
    const request = {
      employeeId,
      presenceTimeAdditionalRepaymentKeyId: additionalRepaymentKeyId,
      date: TimeUtils.getDateForRequest(date),
    }
    return api.postAdditionalRepayment(request)
  },
  startTime(employeeId: string, presenceTimeKeyId: string) {
    return api.postPresenceTime({
      employeeId,
      presenceTimeKeyId,
      from: TimeUtils.getCurrentSeconds(),
      until: 0,
      duration: 0,
      date: TimeUtils.getDateForRequest(),
    })
  },
  stopTime(employeeId: string) {
    return api.stopPresenceTime({
      employeeId,
    })
  },
  findActiveTime(presenceTimes: PresenceTime[] | undefined | null) {
    if (!presenceTimes) return null
    for (const time of presenceTimes) {
      const foundDetail = time.details?.find(d => d.from && !d.until)
      if (foundDetail) return { time, detail: foundDetail } as ActivePresenceTime
    }
    return null
  },
  getTimeDetails(presenceTimes: PresenceTime[] | undefined | null) {
    return Utils.to1DList(presenceTimes ?? [], times => times.details ?? [])
      .filter(detail => !!detail.duration)
      .sort((a, b) => (a.from ?? 0) - (b.from ?? 0))
  },
  sortTimesByDuration(a: PresenceTime, b: PresenceTime) {
    return (b.duration ?? 0) - (a.duration ?? 0)
  },
  sortActivitiesByDuration(a: ActivityTime, b: ActivityTime) {
    return (b.minutes ?? 0) - (a.minutes ?? 0)
  },
  sortKeys(a: PresenceTimeEntry, b: PresenceTimeEntry) {
    // order = 0 means the key has no order and is not normally shown
    return (a.key.order || 1e10) - (b.key.order || 1e10)
  },
  getDayKeyType(times: PresenceTime[] | undefined, keys: PresenceTimeKey[] | undefined) {
    const filteredTimes = times?.filter(
      time => !!time.duration && !!time.presenceTimeTypeId && !!keys?.find(key => key.id === time.presenceTimeTypeId && key.presenceTimeKeyType)
    )
    if (!filteredTimes?.length || !keys?.length) return null
    const totalDurationPerType: { duration: number; type: PresenceTimeKeyType }[] = Object.keys(PresenceTimeKeyType).map(type => ({
      type: type as PresenceTimeKeyType,
      duration: Utils.sum(
        filteredTimes.filter(time => keys.find(key => key.id === time.presenceTimeTypeId)?.presenceTimeKeyType === type),
        time => time.duration ?? 0
      ),
    }))
    totalDurationPerType.sort((a, b) => a.duration - b.duration)
    return totalDurationPerType.pop()?.type
  },
  filterKeyByWeekDay(key: PresenceTimeKey, date: Date) {
    if (!key) return false
    if (!key.activeOnWeekday) return true
    const weekDay = getDay(date)
    switch (weekDay) {
      case 0:
        return !!key.activeOnWeekday.sunday
      case 1:
        return !!key.activeOnWeekday.monday
      case 2:
        return !!key.activeOnWeekday.tuesday
      case 3:
        return !!key.activeOnWeekday.wednesday
      case 4:
        return !!key.activeOnWeekday.thursday
      case 5:
        return !!key.activeOnWeekday.friday
      case 6:
        return !!key.activeOnWeekday.saturday
      default:
        return false
    }
  },
}

export default PresenceTimeUtils
