import { IM, IMStyle, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { NativeSyntheticEvent, Platform, StyleSheet, TextInput, TextInputEndEditingEventData } from 'react-native'

export type TimeNoteProps = {
  editable?: boolean
  value: string | undefined
  spacing?: SpacingProps
  error?: boolean
  setValue: (value: string) => void
  onEndEditing?: (e: NativeSyntheticEvent<TextInputEndEditingEventData>) => void
}

export default function TimeNote({ editable, value, setValue, onEndEditing, spacing, error }: TimeNoteProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()

  const [scrollHeight, setScrollHeight] = useState<number>()

  const multiline = Platform.OS !== 'ios' //Multiline on iOS causes Keyboard Avoiding to not work anymore. Current: react-native 0.70.6

  return (
    <IM.View style={[{ backgroundColor: theme.background }, styles.main]} spacing={spacing}>
      <TextInput
        style={[
          { backgroundColor: theme.card.background, borderColor: theme.input.border, color: theme.text },
          // @ts-ignore: works on web
          // eslint-disable-next-line react-native/no-inline-styles
          Platform.OS === 'web' && { height: scrollHeight, overflowY: 'hidden' },
          IMStyle.layout.shadow,
          !!error && styles.error,
          styles.text,
        ]}
        placeholderTextColor={theme.textPlaceholder}
        editable={editable}
        value={value ?? ''}
        multiline={multiline} //Multiline on iOS causes Keyboard Avoiding to not work anymore. Current: react-native 0.70.6
        onChangeText={setValue}
        placeholder={i18n.t('NOTE')}
        onEndEditing={onEndEditing}
        onContentSizeChange={event => Platform.OS === 'web' && multiline && setScrollHeight(event.nativeEvent.contentSize.height)}
        returnKeyType="done"
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  main: {},
  text: {
    borderWidth: 1,
    borderRadius: IMStyle.layout.borderRadius,
    padding: 5,
  },
  error: {
    borderWidth: 1,
    borderColor: IMStyle.palette.red,
  },
})
