import { IM, IMLayout, useLanguage, useModalController, useTheme, Utils } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'

import DataBar from '../../components/DataBar'
import DurationDisplay from '../../components/DurationDisplay'
import ErrorDisplay from '../../components/ErrorDIsplay'
import useCalendar from '../../components/Infominds/Calendar/hooks/useCalendar'
import SkeletonText from '../../components/Infominds/Skeleton/SkeletonText'
import { useHistory } from '../../contexts/HistoryContext'
import useAllowPresenceTimeEdit from '../../hooks/useAllowPresenceTimeEdit'
import CreatePresenceTimeModal from '../../modals/CreatePresenceTimeModal'
import TimeUtils from '../../utils/TimeUtils'

export default function PresenceTimeSummaryView() {
  const { date } = useCalendar()
  const { i18n } = useLanguage()
  const { theme, themeUtils } = useTheme()
  const createPresenceTimeModal = useModalController()
  const { allowEdit } = useAllowPresenceTimeEdit(date)
  const { presenceTimes: allPresenceTimes, loading, error, reLoad } = useHistory()

  const [showMore, setShowMore] = useState(false)

  const presenceTimes = useMemo(() => allPresenceTimes?.filter(time => !!time.duration), [allPresenceTimes])
  const total = useMemo(() => TimeUtils.roundSecondsToMinutes(Utils.sum(presenceTimes, time => time.duration ?? 0)), [presenceTimes])

  useEffect(() => {
    showMore && setShowMore(false)
  }, [presenceTimes])

  return (
    <>
      <IM.Card spacing="bottom" onPress={allowEdit ? () => createPresenceTimeModal.show() : undefined}>
        {loading || !presenceTimes ? (
          <SkeletonText />
        ) : (
          <>
            <IM.View style={IMLayout.flex.row} spacing="bottom">
              <IM.View style={IMLayout.flex.f1}>
                <IM.Text primary>{i18n.t('TAB_PRESENCE_TIMES')}</IM.Text>
              </IM.View>
              {allowEdit && <IM.Icon icon={['fal', 'pen-to-square']} size={20} color={theme.icon} />}
            </IM.View>
            {presenceTimes?.length === 0 && (
              <>
                {!!error && <ErrorDisplay error={i18n.t('FETCH_TIMES_ERROR')} />}
                {!error && <IM.Text>{i18n.t('NO_TIMES')}</IM.Text>}
              </>
            )}

            {!!presenceTimes.length && (
              <IM.View>
                <DurationDisplay name={i18n.t('TOTAL')} duration={total} formatTimeWithDimensions spacing={'bottom'} />
                <DataBar
                  elements={presenceTimes}
                  totalValue={total}
                  elementDataProvider={time => ({
                    value: TimeUtils.roundSecondsToMinutes(time.duration ?? 0),
                    color: themeUtils.getRandomColorFromTheme(time.presenceTimeType ?? ''),
                  })}
                />
                <IM.View spacing="top">
                  {presenceTimes.map((time, index) => (
                    <DurationDisplay
                      key={`PresenceTime${time.presenceTimeTypeId ?? index}`}
                      name={time.presenceTimeTypeDescription?.trim() ?? ''}
                      color={themeUtils.getRandomColorFromTheme(time.presenceTimeType ?? '')}
                      duration={TimeUtils.roundSecondsToMinutes(time.duration ?? 0)}
                      formatTimeWithDimensions
                    />
                  ))}
                </IM.View>
              </IM.View>
            )}
          </>
        )}
      </IM.Card>
      <CreatePresenceTimeModal controller={createPresenceTimeModal} onClose={reLoad} date={date} />
    </>
  )
}
