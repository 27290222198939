import { IM, useTheme } from '@infominds/react-native-components'
import React, { memo, useEffect } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'
import Animated, { AnimatedStyle, cancelAnimation, Easing, useAnimatedStyle, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated'

interface Props {
  color?: string
  size?: number
  style?: StyleProp<AnimatedStyle<StyleProp<ViewStyle>>>
}

const LoadingIcon = memo(function LoadingIcon({ color, size, style }: Props) {
  const rotation = useSharedValue(0)
  const { theme } = useTheme()

  useEffect(() => {
    rotation.value = withRepeat(
      withTiming(360, {
        duration: 800,
        easing: Easing.linear,
      }),
      0
    )
    return () => cancelAnimation(rotation)
  }, [])

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotateZ: `${rotation.value}deg`,
        },
      ],
    }
  }, [rotation.value])

  return (
    <Animated.View style={[style, styles.container, animatedStyles]}>
      <IM.Icon icon={['fal', 'circle-notch']} color={color ?? theme.textPlaceholder} size={size} />
    </Animated.View>
  )
})

export default LoadingIcon

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
  },
})
