import { DateUtils } from '@infominds/react-native-components'
import { isBefore, subDays } from 'date-fns'
import { useMemo } from 'react'

import useUserSettings from './useUserSettings'

export default function useAllowEmployeeTimeEdit(date?: Date) {
  const { userSettings } = useUserSettings()
  const allowEdit = useMemo(() => allowEditOfDate(date), [date, userSettings])

  function allowEditOfDate(dateToCheck?: Date) {
    return (
      !!userSettings?.isEmployeeTimeManualRecordingActive &&
      (!userSettings?.numberOfDaysForChangesOfHistoricalTimes ||
        !isBefore(DateUtils.dateify(dateToCheck ?? new Date()), subDays(new Date(), userSettings.numberOfDaysForChangesOfHistoricalTimes)))
    )
  }

  return { allowEdit, allowEditOfDate }
}
