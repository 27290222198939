import { IM, IMLayout, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { PresenceTimeDetail } from '../../apis/types/apiResponseTypes'
import Separator from '../../components/Separator'
import StartStopIconButton from '../../components/StartStopIconButton'
import Timer from '../../components/Timer'
import { usePresenceTime } from '../../contexts/PresenceTimeContext'
import { useToast } from '../../contexts/ToastReferenceContext'
import useTimer from '../../hooks/useTimer'
import useUserSettings from '../../hooks/useUserSettings'
import { PresenceTimeEditDetailModalControllerProps } from '../../modals/PresenceTimeEditDetailsModal'
import { PresenceTimeEntry } from '../../types'
import PresenceTimeUtils from '../../utils/PresenceTimeUtils'
import TimeUtils from '../../utils/TimeUtils'
import appUtils from '../../utils/Utils'

interface ActivityCardProps {
  presenceTimeKey: PresenceTimeEntry
  showDetailEditModal: (props: PresenceTimeEditDetailModalControllerProps) => void
}

export default function PresenceTimeKeySubCard({ presenceTimeKey, showDetailEditModal }: ActivityCardProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const alert = useAlert()
  const toast = useToast()
  const { userSettings } = useUserSettings()
  const presenceTime = usePresenceTime()
  const editDisabled = !userSettings?.isPresenceTimeManualRecordingActive

  const activeTime = useMemo(
    () => (presenceTime.activeTime?.time.presenceTimeTypeId === presenceTimeKey.key.id ? presenceTime.activeTime : null),
    [presenceTimeKey]
  )
  const detailsToShow = useMemo(() => PresenceTimeUtils.getTimeDetails(presenceTimeKey.times), [presenceTimeKey])
  const timer = useTimer()
  const startTimeText = useMemo(
    () => !!activeTime && `${i18n.t('StartTime')} ${TimeUtils.formatSeconds(activeTime.detail.from ?? 0, undefined, true)}`,
    [activeTime]
  )

  useEffect(() => {
    if (!activeTime) timer.stop()
    else timer.start()
  }, [activeTime])

  async function startTime() {
    try {
      await PresenceTimeUtils.startTime(userSettings?.employee.id ?? '', presenceTimeKey?.key.id ?? '')
      await presenceTime.load()
    } catch (exception) {
      console.error('startTime Error', exception)
      if (appUtils.isFetchError(exception)) alert.alert(exception.Message)
      else toast.show(i18n.t('ERROR_START_TIME'), { type: 'danger' })
    }
  }

  async function stopTime() {
    try {
      await PresenceTimeUtils.stopTime(userSettings?.employee.id ?? '')
      await presenceTime.load()
    } catch (exception) {
      console.error('stopTime Error', exception)
      if (appUtils.isFetchError(exception)) alert.alert(exception.Message)
      else toast.show(i18n.t('ERROR_STOP_TIME'), { type: 'danger' })
    }
  }

  if (presenceTime.dateMode && !detailsToShow.length) return <></>

  return (
    <IM.View style={[{ backgroundColor: theme.card.background }, styles.main, IMLayout.shadow]}>
      <IM.View style={[IMLayout.flex.row]}>
        {!presenceTime.dateMode && (
          <>
            <StartStopIconButton isStarted={!!activeTime} onStartPressed={startTime} onStopPressed={stopTime} />
            <IM.View style={IMLayout.flex.f1}>
              {!!activeTime && (
                <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row, styles.timer]}>
                  <IM.Text secondary style={IMLayout.flex.f1}>
                    {startTimeText}
                  </IM.Text>
                  <Timer timeS={activeTime.detail.from ?? 0} runTimer />
                </IM.View>
              )}
              {!activeTime && !editDisabled && (
                <IM.View style={styles.addTimeView}>
                  <IM.PressableIcon
                    icon={['fal', 'plus']}
                    color={theme.icon}
                    onPress={() => showDetailEditModal({ id: presenceTimeKey.key.id, addTime: true })}
                  />
                </IM.View>
              )}
            </IM.View>
          </>
        )}
      </IM.View>
      {!!detailsToShow.length && (
        <IM.View>
          {!presenceTime.dateMode && <Separator />}
          <Pressable disabled={editDisabled} onPress={() => showDetailEditModal({ id: presenceTimeKey.key.id })}>
            {detailsToShow.map(detail => (
              <DetailEntry key={`Detail${detail.id}`} detail={detail} />
            ))}
          </Pressable>
        </IM.View>
      )}
    </IM.View>
  )
}

function DetailEntry({ detail }: { detail: PresenceTimeDetail }) {
  const timeText = useMemo(
    () =>
      `${TimeUtils.formatMinutes(TimeUtils.roundSecondsToMinutes(detail.from ?? 0), undefined, true)} - ${TimeUtils.formatMinutes(
        TimeUtils.roundSecondsToMinutes(detail.until ?? 0)
      )}`,
    [detail]
  )
  const durationText = useMemo(() => TimeUtils.formatMinutes(TimeUtils.roundSecondsToMinutes(detail.duration ?? 0), undefined, true), [detail])

  return (
    <IM.View style={IMLayout.flex.row}>
      <IM.View style={IMLayout.flex.f1}>
        <IM.TextWithIcon secondary icon={['fal', 'timer']}>
          {timeText}
        </IM.TextWithIcon>
      </IM.View>
      <IM.Text secondary>{durationText}</IM.Text>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  main: {
    zIndex: -1,
    marginHorizontal: IMLayout.borderRadius,
    borderBottomLeftRadius: IMLayout.borderRadius,
    borderBottomRightRadius: IMLayout.borderRadius,
    justifyContent: 'center',
    padding: 10,
  },
  timer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
  },
  addTimeView: {
    alignSelf: 'flex-end',
  },
  plusButtonView: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
