import { IM, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Pressable } from 'react-native'

import type { Document } from '../../apis/types/apiResponseTypes'
import DocumentCard from '../../cards/DocumentCard'
import BadgeWithText from '../../components/BadgeWithText'
import IMContentCard from '../../components/Card/IMContentCard'
import DocumentSelectionModal from '../../modals/DocumentSelectionModal'

export interface Props {
  disabled?: boolean
  selectedDocument: Document | undefined
  allowUnselected?: boolean
  error?: boolean
  setSelectedDocument: React.Dispatch<React.SetStateAction<Document | undefined>>
}
export default function DocumentSelectionView({ disabled, selectedDocument, allowUnselected = true, error, setSelectedDocument }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()

  const controller = useModalController()

  function handleSelectedDocumentPressed() {
    if (disabled) return

    if (allowUnselected) setSelectedDocument(undefined)
    else !controller.isShown && controller.show()
  }

  return (
    <IMContentCard title={i18n.t('DOCUMENT')} spacing="all" error={error}>
      <IM.View spacing="all">
        {!!selectedDocument && (
          <DocumentCard
            document={selectedDocument}
            onPress={handleSelectedDocumentPressed}
            editable={!disabled}
            fetchDocumentCount={disabled}
            borderless
          />
        )}
        {!selectedDocument && (
          <Pressable disabled={disabled} onPress={() => controller.show()}>
            <BadgeWithText
              textProps={{ secondary: true }}
              badgeProps={{ children: 'PX', textStyle: { fontSize: 16 }, backgroundColor: theme.card.headBackground }}>
              {i18n.t('SELECT_PLACEHOLDER')}
            </BadgeWithText>
          </Pressable>
        )}
      </IM.View>

      {!disabled && <DocumentSelectionModal controller={controller} onSelected={setSelectedDocument} />}
    </IMContentCard>
  )
}
