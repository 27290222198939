import { IM, IMLayout, SpacingProps, SpacingType, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { Platform, Pressable as RNPressable, PressableProps as RNPressableProps, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { AnimatedStyle } from 'react-native-reanimated'

export interface PressableProps extends RNPressableProps {
  spacing?: SpacingProps
  spacingType?: SpacingType
  containerStyle?: StyleProp<AnimatedStyle<StyleProp<ViewStyle>>>
  disableOpacity?: boolean
}

const Pressable = memo(function Pressable({ disableOpacity = false, spacing, spacingType, containerStyle, ...pressableProps }: PressableProps) {
  const { theme } = useTheme()

  return (
    // @ts-ignore works on web
    <IM.View spacing={spacing} spacingType={spacingType} style={[containerStyle, Platform.select({ web: { userSelect: 'none' } })]}>
      <RNPressable
        {...pressableProps}
        // @ts-ignore it works but we need to discover how to implement it on ts
        style={({ pressed }) => [
          {
            backgroundColor: pressed ? (disableOpacity ? 'transparent' : theme.button.pressedOpacity) : 'transparent',
          },
          styles.pressable,
          pressableProps.style,
        ]}
      />
    </IM.View>
  )
})

export default Pressable

const styles = StyleSheet.create({
  pressable: {
    justifyContent: 'center',
    borderRadius: IMLayout.borderRadius,
    padding: 8,
  },
})
