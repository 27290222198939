import { ScannerModal } from '@infominds/react-native-barcode-scanner'
import { IM, SpacingProps, useDimensions, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useState } from 'react'
import { Alert, Platform } from 'react-native'
import { OrientationType } from 'react-native-orientation-locker'

import api from '../apis/apiCalls'
import type { Document } from '../apis/types/apiResponseTypes'

export type SearchBoxProps = {
  spacing?: SpacingProps
  setSearch: (text: string) => void
  onDocumentScanned: (document: Document) => void
}

const DocumentsSearchBox = ({ setSearch, onDocumentScanned, spacing }: SearchBoxProps) => {
  const { i18n, language } = useLanguage()
  const { colorScheme } = useTheme()
  const { isSmallDevice } = useDimensions()
  const [showScannerModal, setShowScannerModal] = useState(false)

  function handleBarCodeScanned(barcode: string[]) {
    setShowScannerModal(false)
    api
      .getDocuments({ barcode: barcode[0] })
      .then((result: Document[]) => {
        if (!result?.length) {
          handleBarCodeScanError()
          return
        }
        onDocumentScanned(result[0])
      })
      .catch(err => {
        console.error('Failed loadDocuments() on handleBarCodeScanned', err)
        handleBarCodeScanError()
      })
  }

  function handleBarCodeScanError() {
    Alert.alert('', i18n.t('NO_DOCUMENT_FOUND'), undefined, { userInterfaceStyle: colorScheme })
  }

  return (
    <IM.View spacing={spacing}>
      <IM.SearchBox
        placeholder={i18n.t('SEARCH_PLACEHOLDER')}
        onEnterEnd={setSearch}
        deferredTimeout={500}
        icon={['fal', 'qrcode']}
        onIconPress={() => setShowScannerModal(true)}
        hideIcon={Platform.OS !== 'android' && Platform.OS !== 'ios'}
      />
      <ScannerModal
        languageCode={language}
        isVisible={showScannerModal}
        onClose={() => setShowScannerModal(false)}
        onScanned={handleBarCodeScanned}
        scannerPostCloseOrientation={isSmallDevice ? OrientationType.PORTRAIT : undefined}
      />
    </IM.View>
  )
}

export default memo(DocumentsSearchBox)
