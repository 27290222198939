import { useLanguage } from '@infominds/react-native-components'
import { NavigationProp, useFocusEffect, useNavigation } from '@react-navigation/native'
import React, { useCallback, useMemo, useState } from 'react'

import KeyboardAvoidingScrollView from '../../components/Infominds/KeyboardAvoidingScrollView'
import Screen from '../../components/Infominds/Screen'
import { ScreenHeader } from '../../components/ScreenHeader'
import TimeHandlerButton from '../../components/TimeHandlerButton'
import { useEmployeeTime } from '../../contexts/EmployeeTimeContext'
import useLayout from '../../hooks/useLayout'
import useNewTimeHandler from '../../hooks/useNewTimeHandler'
import useUserSettings from '../../hooks/useUserSettings'
import { NewTimeModalData } from '../../modals/NewTimeModal'
import { HistoryStackScreenProps, HomeStackParamList, HomeStackScreenProps } from '../../navigation/type'
import { ModalScreenProps } from '../../types'
import DateUtils from '../../utils/DateUtils'
import CurrentTimeView from '../../views/NewTime/CurrentTimeView'
import TimeSelectionView from '../../views/NewTime/TimeSelectionView'

export default function NewTimeScreen({
  route,
  controller,
}: Omit<HomeStackScreenProps<'NewTime'> | HistoryStackScreenProps<'HistoryNewTime'>, 'navigation'> & ModalScreenProps<NewTimeModalData>) {
  const { language } = useLanguage()
  const { isSmallDevice } = useLayout()
  const { userSettings } = useUserSettings()
  const navigation = useNavigation<NavigationProp<HomeStackParamList>>()

  const employeeTime = useEmployeeTime()
  const handler = useNewTimeHandler({
    presetActivity: route.params?.activity,
    presetDocument: route.params?.document,
    activeTime: employeeTime.currentTime,
    presetEmployees: employeeTime.lastTime?.subordinates,
  })

  const [showLastTimeId, setShowLastTimeId] = useState<string | undefined>(employeeTime.currentTime?.id)

  useFocusEffect(
    useCallback(() => {
      employeeTime.getCurrentTime().catch(console.error)
    }, [])
  )

  const showTime = useMemo(() => {
    if (employeeTime.isTimeActive) return employeeTime.currentTime
    if (!showLastTimeId) return undefined
    return employeeTime.todaysTimes.find(time => time.id === showLastTimeId)
  }, [employeeTime.currentTime, employeeTime.todaysTimes])

  //if time is active send new note to api
  async function handleNoteChanged(note: string) {
    if (!employeeTime.currentTime || note === employeeTime.currentTime?.note || (!note && !employeeTime.currentTime?.note)) {
      return
    }
    return employeeTime.updateCurrentTime(handler.note)
  }

  return (
    <Screen
      hideBackgroundImage={!isSmallDevice}
      screenHeader={
        <ScreenHeader
          goBack={() => {
            isSmallDevice ? navigation.goBack() : controller?.close()
          }}
          title={DateUtils.formatDate(new Date(), 'dd MMMM yyyy', language)}
        />
      }>
      <KeyboardAvoidingScrollView>
        <TimeSelectionView
          handler={handler}
          activeTime={employeeTime.currentTime}
          onNoteChanged={handleNoteChanged}
          creationMode={!userSettings?.isEmployeeTimeOnlineRecordingActive}
          isCreationAlwaysAllowed
        />
      </KeyboardAvoidingScrollView>

      {!!showTime && <CurrentTimeView time={showTime} />}
      <TimeHandlerButton
        handler={handler}
        spacing="all"
        postStart={setShowLastTimeId}
        postStop={() => navigation.goBack()}
        creationMode={!userSettings?.isEmployeeTimeOnlineRecordingActive}
        postCreated={() => navigation.goBack()}
      />
    </Screen>
  )
}
