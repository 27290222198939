import { useAlert, useEvent, useLanguage, Utils } from '@infominds/react-native-components'
import { assetManagementUtils, AssetView } from '@infominds/react-native-media'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'
import { Platform } from 'react-native'

import api from '../../apis/apiCalls'
import { REFRESH_INFOBOX_EVENT_KEY } from '../../constants/EmitterKeys'
import { InfoBoxStackParamList } from '../../navigation/type'
import { uuid } from '../../utils/uuid'

interface Props {
  id: string
  infoboxType: string
}

let abortController: AbortController | undefined

const InfoBoxAssetView = ({ id, infoboxType }: Props) => {
  const { alert } = useAlert()
  const { i18n } = useLanguage()
  const navigation = useNavigation<NavigationProp<InfoBoxStackParamList>>()

  const [error, setError] = useState(false)
  const [uniqueId, setUniqueId] = useState(uuid.generate())

  useEvent({ key: REFRESH_INFOBOX_EVENT_KEY }, () => {
    setUniqueId(uuid.generate())
  })

  const fetchHighResolutionAsset = (idSelected: string) => {
    if (abortController) {
      abortController.abort()
    }

    setError(false)
    abortController = new AbortController()

    return new Promise<{ path?: string; base64?: string }>((resolve, reject) => {
      api
        .getInfoboxFile({ id: idSelected, infoboxTyp: infoboxType })
        .then(result => {
          if (!result) {
            reject('no data')
            return
          }
          if (Platform.OS === 'web') {
            resolve({ base64: result.data })
          } else {
            const hrPath = assetManagementUtils.getCacheDir() + `/highRes/${result.filename}`
            assetManagementUtils
              .writeFile(hrPath, result.data, 'base64')
              .then(() => {
                resolve({ path: hrPath })
              })
              .catch(err => {
                setError(true)
                alert(i18n.t('ERROR'), Utils.stringValueReplacer(i18n.t('ERROR_SAVING_FILE'), hrPath, err))
                reject(err)
              })
          }
        })
        .catch(err => {
          setError(true)
          alert(i18n.t('ERROR'), Utils.stringValueReplacer(i18n.t('ERROR_FETCH_ASSET'), err))
          reject(err)
        })
    })
  }

  return (
    <AssetView
      key={uniqueId}
      id={id}
      disableInfoButton={error}
      onGoBack={() => navigation.goBack()}
      // onAssetInfo={() => navigation.navigate('InfoboxNoBottomTabAssetInfo', { id, origin, infoboxType })}
      fetchHighResAsset={fetchHighResolutionAsset}
    />
  )
}

export default InfoBoxAssetView
