import { IM, ModalController, useAlert, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import type Toast from 'react-native-toast-notifications'

import api from '../apis/apiCalls'
import type { PatchEmployeeTimeRequest } from '../apis/types/apiRequestTypes'
import type { EmployeeTime } from '../apis/types/apiResponseTypes'
import LoadingSpinnerModal from '../components/Infominds/LoadingSpinnerModal'
import Modal from '../components/Infominds/Modal'
import { ScreenHeader } from '../components/ScreenHeader'
import { useEmployeeTime } from '../contexts/EmployeeTimeContext'
import useAllowEmployeeTimeEdit from '../hooks/useAllowEmployeeTimeEdit'
import useNewTimeHandler from '../hooks/useNewTimeHandler'
import useUserSettings from '../hooks/useUserSettings'
import DateUtils from '../utils/DateUtils'
import appUtils from '../utils/Utils'
import TimeSelectionView from '../views/NewTime/TimeSelectionView'

export type ModifyTimeData = {
  employeeTime: EmployeeTime
}

interface Props {
  onTimeModified?: () => void
  controller: ModalController<ModifyTimeData>
}

export default function ModifyTimeModal({ controller, onTimeModified }: Props) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const timeToModify = controller.data?.employeeTime
  const toastRef = useRef<Toast | undefined>(undefined)
  const { allowEdit } = useAllowEmployeeTimeEdit(timeToModify?.date)

  const employeeTime = useEmployeeTime()
  const { userSettings } = useUserSettings()
  const handler = useNewTimeHandler({ presetDate: timeToModify?.date, activeTime: controller.isShown ? timeToModify : undefined, mode: 'modify' })

  const [busy, setBusy] = useState(false)

  const changed =
    !!timeToModify &&
    (timeToModify.employeeTimeTypeId !== handler.selectedActivity?.id ||
      timeToModify.documentId !== handler.selectedDocument?.id ||
      timeToModify.from !== handler.from ||
      timeToModify.until !== handler.until ||
      (timeToModify.note !== handler.note && (!!timeToModify.note || !!handler.note)))

  const canSave = allowEdit && changed && !handler.missingDataToCreate?.length

  useEffect(() => {
    if (controller.isShown && !timeToModify) {
      controller.close()
      return
    }
  }, [controller.isShown])

  async function save() {
    if (!timeToModify || !changed) {
      controller.close()
      return
    }

    let applyChangesToAssociatedTimes = false
    if (handler.employeeSelector.any && !!userSettings?.subordinates?.length) {
      //for complete times, ask if changes are to be applied to all associated times
      if (timeToModify.until) {
        const askResult = await appUtils.changeTimeAlert(
          i18n,
          alert,
          i18n.t('MODIFY_TIME_TITLE'),
          i18n.t('ASK_APPLY_CHANGES_TO_ASSOCIATED_TIMES'),
          timeToModify.subordinates
        )
        if (askResult === 'abort') return
        applyChangesToAssociatedTimes = askResult === 'all'
      }
      //for still running times automatically apply changes to all associated times
      else {
        applyChangesToAssociatedTimes = true
      }
    }

    setBusy(true)

    const request: PatchEmployeeTimeRequest = { id: timeToModify.id, note: handler.note, changeAssociatedTimes: applyChangesToAssociatedTimes }

    if (handler.selectedActivity?.id !== timeToModify.employeeTimeTypeId) request.employeeTimeTypeId = handler.selectedActivity?.id
    if (handler.selectedDocument?.id !== timeToModify.documentId) {
      request.documentId = handler.selectedDocument?.id
      request.documentType = handler.selectedDocument?.documentType
      request.pspDetailId = handler.selectedDocument?.pspDetailId ?? undefined
    }
    if (handler.from !== timeToModify.from) request.from = handler.from
    if (handler.until !== timeToModify.until) request.until = handler.until

    await api.patchEmployeeTime(request)
    if (employeeTime) await employeeTime.getCurrentTime()

    setBusy(false)
    if (onTimeModified) onTimeModified()
    controller.close()
  }

  return (
    <Modal
      statusBarTranslucent
      isVisible={controller.isShown}
      onClose={controller.close}
      toastRef={toastRef}
      screenHeader={
        <ScreenHeader
          goBack={() => {
            controller.close()
          }}
          title={DateUtils.formatDate(timeToModify?.date, 'dd MMMM yyyy')}
          icon={canSave ? ['fal', 'check'] : undefined}
          onIconPress={() => {
            save().catch(console.error)
          }}
        />
      }>
      <IM.ScrollView>
        <TimeSelectionView handler={handler} creationMode disableEmployeeSelection allowDocumentUnselecting={false} toastRef={toastRef} />
      </IM.ScrollView>
      <LoadingSpinnerModal isVisible={busy} />
    </Modal>
  )
}
