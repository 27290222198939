import { IM, useEvent, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import cloneDeep from 'lodash/cloneDeep'
import React, { useEffect, useState } from 'react'
import { FlatList, Platform, StyleSheet } from 'react-native'
import { useRecoilState } from 'recoil'

import api from '../../apis/apiCalls'
import type { Document, Folder } from '../../apis/types/apiResponseTypes'
import IMRefreshControl from '../../components/IMRefreshControl'
import SkeletonCard from '../../components/Infominds/Skeleton/SkeletonCard'
import NoEntry from '../../components/NoEntry'
import { CONSTANTS } from '../../constants/Constants'
import { INFOBOX_FOLDER_EVENT_KEY } from '../../constants/EmitterKeys'
import { useToast } from '../../contexts/ToastReferenceContext'
import useLayout from '../../hooks/useLayout'
import { InfoBoxStackParamList } from '../../navigation/type'
import { documentCountAtom } from '../../utils/stateManager'
import appUtils from '../../utils/Utils'

let folderAbortController: AbortController | undefined

interface Props {
  document: Document | undefined
  selectedFolder?: Folder
  onFolderPress?: (folder: Folder) => void
  onResetSelection?: () => void
}

// TODO RT: cancel api calls when component is unmounted
export default function InfoBoxFolderView({ document, selectedFolder, onFolderPress, onResetSelection }: Props) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const navigation = useNavigation<NavigationProp<InfoBoxStackParamList>>()
  const toast = useToast()
  const { isSmallDevice } = useLayout()

  const [loading, setLoading] = useState(true)
  const [folders, setFolders] = useState<Folder[]>([])
  const [counts, setCounts] = useRecoilState(documentCountAtom)

  useEvent({ key: INFOBOX_FOLDER_EVENT_KEY }, () => {
    if (!document) return
    validateDocAndUpdateCount(folders, document)
  })

  useEffect(() => {
    if (!document) return

    setLoading(true)
    refreshFolders(document)

    return () => folderAbortController && folderAbortController.abort()
  }, [document])

  function refreshFolders(inputDocument: Document) {
    if (folderAbortController !== undefined) {
      folderAbortController.abort()
      folderAbortController = undefined
    }

    folderAbortController = new AbortController()

    setLoading(true)
    setFolders([])
    setCounts([])

    api
      .getInfoboxFolders({ infoboxTyp: inputDocument.documentType }, folderAbortController)
      .then(fetchedFolders => {
        setFolders(fetchedFolders)
        validateDocAndUpdateCount(fetchedFolders, inputDocument)
      })
      .catch(err => {
        if (appUtils.isAbortError(err)) {
          return
        }

        console.error(`Failed loading infobox folder for docId ${inputDocument.id}:`, err)
        if (isSmallDevice) {
          navigation.goBack()
        } else {
          onResetSelection && onResetSelection()
        }
        toast.show(Utils.stringValueReplacer(i18n.t('FOLDER_FETCH_ERROR'), inputDocument.documentType), {
          type: 'danger',
        })
        setLoading(false)
        folderAbortController = undefined
      })
  }

  function validateDocAndUpdateCount(newFolders: Folder[], inputDocument: Document) {
    api
      .getDocuments({ id: inputDocument.id }, folderAbortController)
      .then(val => {
        if (val.length !== 0) {
          updateDocumentCount(newFolders, inputDocument)
        } else {
          if (isSmallDevice) {
            navigation.goBack()
          } else {
            onResetSelection && onResetSelection()
          }
          toast.show(Utils.stringValueReplacer(i18n.t('FILE_FETCH_ERROR'), inputDocument.code), {
            type: 'warning',
          })
          console.error(`Failed loading infobox files for docId ${inputDocument.id}`)
          folderAbortController = undefined
        }
      })
      .catch(err => {
        if (appUtils.isAbortError(err)) {
          return
        }

        console.error(`Failed fetching ${inputDocument.id}`, err)
        setLoading(false)
        folderAbortController = undefined
      })
  }

  function updateDocumentCount(newFolders: Folder[], inputDocument: Document) {
    api
      .getInfoboxFiles({ id: inputDocument.id, infoboxTyp: inputDocument.documentType }, folderAbortController)
      .then(fetchedFiles => {
        const foldersCopy = cloneDeep(newFolders)
        foldersCopy.sort((prev, curr) => {
          return curr.number - prev.number
        })

        if (foldersCopy[0]?.number) {
          const newItem = Array(foldersCopy[0]?.number + 1).fill(0)

          fetchedFiles.forEach(elem => {
            newItem[elem.folderNumber] += 1
          })
          setCounts(newItem)
        }
      })
      .catch(err => {
        if (appUtils.isAbortError(err)) {
          return
        }

        console.error(`Failed loading infobox files for docId ${inputDocument.id}:`, err)
      })
      .finally(() => {
        setLoading(false)
        folderAbortController = undefined
      })
  }

  const handleFolderPress = (doc: Document, folder: Folder) => {
    if (isSmallDevice) {
      navigation.navigate('InfoboxMedia', {
        document: doc,
        folder: folder,
      })
    } else {
      onFolderPress && onFolderPress(folder)
    }
  }

  return (
    <>
      <IM.View spacing="top" />
      {document ? (
        <>
          {loading ? (
            <IM.View spacing={['bottom', 'horizontal']}>
              {Array(CONSTANTS.InfoboxSkeletonCard)
                .fill(0)
                .map((_val, index) => {
                  return (
                    <IM.View spacing="bottom" key={`SkeletonDataInfobox-${index}`}>
                      <SkeletonCard />
                    </IM.View>
                  )
                })}
            </IM.View>
          ) : (
            <FlatList
              data={folders}
              renderItem={({ item }) => {
                return (
                  <IM.View spacing={['bottom', 'horizontal']}>
                    <IM.Card
                      head={{ backGroundColor: theme.card.headBackground, icon: ['fal', 'folder'] }}
                      defaultContent={{
                        texts: [
                          {
                            text: item.description ?? i18n.t('FOLDER_WITHOUT_NAME'),
                            primary: true,
                          },
                          {
                            text:
                              counts[item.number] !== undefined
                                ? appUtils.manageDocumentsNumber(i18n, counts[item.number])
                                : i18n.t('LOADING_PLACEHOLDER'),
                            secondary: true,
                          },
                        ],
                      }}
                      onPress={() => {
                        handleFolderPress(document, item)
                      }}
                      style={!isSmallDevice && selectedFolder?.id === item.id && [styles.selected, { borderColor: theme.primary }]}
                    />
                  </IM.View>
                )
              }}
              refreshControl={Platform.OS !== 'web' ? <IMRefreshControl refreshing={false} onRefresh={() => refreshFolders(document)} /> : undefined}
            />
          )}
        </>
      ) : (
        <NoEntry description={i18n.t('NO_DOCUMENT_SELECTED')} />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  selected: {
    borderRightWidth: 4,
  },
})
