import { IM, IMLayout, useModalController } from '@infominds/react-native-components'
import { NavigationProp, useFocusEffect, useNavigation } from '@react-navigation/native'
import { addDays, isSameDay, startOfWeek } from 'date-fns'
import React, { useCallback, useEffect, useRef } from 'react'
import { Platform } from 'react-native'

import IMRefreshControl from '../../components/IMRefreshControl'
import useCalendar from '../../components/Infominds/Calendar/hooks/useCalendar'
import { useHistory } from '../../contexts/HistoryContext'
import useIsEmployeeTimeEnabled from '../../hooks/useIsEmployeeTimeEnabled'
import useIsPresenceTimeEnabled from '../../hooks/useIsPresenceTimeEnabled'
import useLayout from '../../hooks/useLayout'
import NewTimeModal, { NewTimeModalData } from '../../modals/NewTimeModal'
import { HistoryStackParamList } from '../../navigation/type'
import EmployeeTimeDetailedView from '../Common/EmployeeTimeDetailedView'
import AdditionalRepaymentsSummaryView from './AdditionalRepaymentsSummaryView'
import DateView from './DateView'
import EmployeeTimeSummaryView from './EmployeeTimeSummaryView'
import PresenceTimeActivitySummaryView from './PresenceTimeActivitySummaryView'
import PresenceTimeSummaryView from './PresenceTimeSummaryView'

export default function HistoryDayView() {
  const { date } = useCalendar()
  const { isSmallDevice } = useLayout()
  const navigation = useNavigation<NavigationProp<HistoryStackParamList>>()
  const isPresenceTimeEnabled = useIsPresenceTimeEnabled()
  const isEmployeeTimeEnabled = useIsEmployeeTimeEnabled()
  const newTimeController = useModalController<NewTimeModalData>()
  const { employeeTimes, error, loading, reLoad, loadPresenceTimesRange } = useHistory()

  const currentWeekStart = useRef<Date | null>(null)

  useFocusEffect(useCallback(() => reLoad(), []))

  useEffect(() => {
    if (!isPresenceTimeEnabled) return
    const weekStartDate = startOfWeek(date, { weekStartsOn: 1 })
    if (!weekStartDate) return
    if (!currentWeekStart.current || !isSameDay(currentWeekStart.current, weekStartDate)) {
      loadPresenceTimesRange(weekStartDate, addDays(weekStartDate, 6)).catch(console.error)
      currentWeekStart.current = weekStartDate
    }
  }, [date])

  const handleNavigationToNewTime = () => {
    if (isSmallDevice) {
      navigation.navigate('HistoryNewTime')
    } else {
      !newTimeController.isShown && newTimeController.show({})
    }
  }

  return (
    <IM.View style={IMLayout.flex.f1}>
      {isEmployeeTimeEnabled && (
        <>
          <IM.MasterDetail>
            <IM.MasterDetail.Master>
              <IM.ScrollView
                refreshControl={Platform.OS !== 'web' ? <IMRefreshControl refreshing={loading} onRefresh={reLoad} /> : undefined}
                spacing="horizontal">
                <DateView />
                {isPresenceTimeEnabled && <PresenceTimeActivitySummaryView />}
                {isPresenceTimeEnabled && <PresenceTimeSummaryView />}
                <EmployeeTimeSummaryView loading={loading} times={employeeTimes} error={error} reLoad={reLoad} />
                {isPresenceTimeEnabled && <AdditionalRepaymentsSummaryView />}
                {isSmallDevice && (
                  <EmployeeTimeDetailedView
                    times={employeeTimes}
                    onUpdate={reLoad}
                    spacing="bottom"
                    onNavigateToNewTime={handleNavigationToNewTime}
                  />
                )}
              </IM.ScrollView>
            </IM.MasterDetail.Master>
            <IM.MasterDetail.Detail>
              <IM.ScrollView
                refreshControl={Platform.OS !== 'web' ? <IMRefreshControl refreshing={loading} onRefresh={reLoad} /> : undefined}
                spacing="horizontal">
                <EmployeeTimeDetailedView
                  times={employeeTimes}
                  onUpdate={reLoad}
                  onNavigateToNewTime={handleNavigationToNewTime}
                  spacing="vertical"
                />
              </IM.ScrollView>
            </IM.MasterDetail.Detail>
          </IM.MasterDetail>
        </>
      )}
      {!isEmployeeTimeEnabled && isPresenceTimeEnabled && (
        <IM.ScrollView
          refreshControl={Platform.OS !== 'web' ? <IMRefreshControl refreshing={loading} onRefresh={reLoad} /> : undefined}
          spacing="horizontal">
          <DateView />
          <PresenceTimeActivitySummaryView />
          <PresenceTimeSummaryView />
          <AdditionalRepaymentsSummaryView />
        </IM.ScrollView>
      )}
      <NewTimeModal controller={newTimeController} />
    </IM.View>
  )
}
