import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import { LoginScreen, OverrideThemeColors, useAuthentication } from '@infominds/react-native-license'
import React, { useEffect, useState } from 'react'
import { ActivityIndicator, ImageSourcePropType, Platform, StyleSheet } from 'react-native'
import { OrientationType } from 'react-native-orientation-locker'
import { useRecoilValue } from 'recoil'

import icon from '../../assets/img/icon.png'
import DemoMode from '../../constants/DemoMode'
import useLayout from '../../hooks/useLayout'
import { landingPageUrlAtom } from '../../utils/stateManager'

const styles = StyleSheet.create({
  loading: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
})

export default function AppLoginScreen() {
  const { isSmallDevice } = useLayout()
  const { theme, colorScheme } = useTheme()
  const { isLoggedIn } = useAuthentication()

  const [loading, setLoading] = useState(true)
  const landingPage = useRecoilValue(landingPageUrlAtom)

  useEffect(() => {
    if (isLoggedIn === false) setLoading(false)
  }, [isLoggedIn])

  function onSuccessfulLogin() {
    if (Platform.OS === 'web') window.history.pushState(null, document.title, landingPage)
  }

  const colors: OverrideThemeColors = {
    dark: { background: '#1E1E1E', button: { default: { background: { disable: '#2F2F2F' }, text: { disable: '#4E4E4E' } } } },
    light: { background: '#F5F5F5', button: { default: { background: { disable: '#ECECEC' }, text: { disable: '#BBBBBB' } } } },
  }

  return (
    <>
      {loading && (
        <IM.View style={[{ backgroundColor: theme.card.background }, styles.loading]}>
          <ActivityIndicator size="large" color={IMStyle.palette.tint} />
        </IM.View>
      )}
      <LoginScreen
        demoData={{
          license: DemoMode.DEMO_LICENSE_KEY,
          username: DemoMode.DEMO_USERNAME,
          password: DemoMode.DEMO_PASSWORD,
        }}
        iconSource={icon as ImageSourcePropType}
        onSuccess={onSuccessfulLogin}
        colorScheme={colorScheme}
        overrideThemeColors={colors}
        codeScannerOrientationOnExit={isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios') ? OrientationType.PORTRAIT : undefined}
      />
    </>
  )
}
