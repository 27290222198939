import { IM, IMLayout } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

type Props = {
  title: string
  compact?: boolean
  isSwitch?: boolean
  style?: StyleProp<ViewStyle>
}

export default function SettingsRow({ title, style, compact, isSwitch, children }: PropsWithChildren<Props>) {
  return (
    <IM.View style={[style, styles.row, isSwitch && styles.switchRow, compact && styles.compact]} spacing="horizontal">
      <IM.Text style={[IMLayout.flex.f1, styles.text]}>{title}</IM.Text>
      {children}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  row: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', minHeight: 50 },
  compact: { minHeight: 25 },
  switchRow: { minHeight: 34 },
  text: { marginRight: 12 },
})
