import { DateUtils, IM, IMLayout, IMStyle, LoadingIndicator, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { useIsFocused } from '@react-navigation/native'
import { endOfMonth, isSameDay, isSameMonth, startOfMonth, startOfWeek } from 'date-fns'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'
import { CalendarList } from 'react-native-calendars'

import { PresenceTimeKey, PresenceTimeKeyType } from '../../apis/types/apiResponseTypes'
import { UpdateSources } from '../../components/Infominds/Calendar/context/CalendarContext'
import useCalendar from '../../components/Infominds/Calendar/hooks/useCalendar'
import { PresenceTimesOfDay, useHistory } from '../../contexts/HistoryContext'
import useAllowPresenceTimeEdit from '../../hooks/useAllowPresenceTimeEdit'
import useCalendarDayColor from '../../hooks/useCalendarDayColor'
import useUserSettings from '../../hooks/useUserSettings'
import CreatePresenceTimeModal from '../../modals/CreatePresenceTimeModal'
import { ThemeColorExpanded } from '../../types'
import PresenceTimeCalendarUtils from '../../utils/PresenceTimeCalendarUtils'
import TimeUtils from '../../utils/TimeUtils'

type CalendarHeaderProps = {
  month: Date
  displayLoadingIndicator: boolean
}

export default function HistoryCalendarView() {
  const isFocused = useIsFocused()
  const { date, setDate } = useCalendar()
  const { userSettings } = useUserSettings()
  const { getDayColorByKeyType } = useCalendarDayColor()
  const { theme } = useTheme<ThemeColorExpanded>()
  const createPresenceTimeModal = useModalController()
  const { presenceTimesOfDay, presenceTimeKeys, loadPresenceTimesRange, loading } = useHistory()
  const { minEditDate } = useAllowPresenceTimeEdit(new Date())

  const [calendarMonth, setCalendarMonth] = useState<Date>(new Date())
  const [markedDates, setMarkedDates] = useState(createMarkedDates(presenceTimesOfDay, presenceTimeKeys))

  const loadedMonths = useRef<Date[]>([])

  const initialDate = useMemo(() => new Date(), [])
  const allowEdit = !!userSettings?.isPresenceTimeManualRecordingActive

  useEffect(() => {
    setMarkedDates(createMarkedDates(presenceTimesOfDay, presenceTimeKeys))
  }, [presenceTimesOfDay, presenceTimeKeys])

  useEffect(() => {
    if (!isFocused) return
    loadedMonths.current = []
    setCalendarMonth(new Date())
  }, [isFocused])

  useEffect(() => {
    if (!calendarMonth) return
    setDate(calendarMonth, UpdateSources.WEEK_SCROLL)
    if (loadedMonths.current.find(m => isSameMonth(m, calendarMonth))) return

    loadPresenceTimesRange(startOfMonth(calendarMonth), endOfMonth(calendarMonth))
      .then(() => loadedMonths.current.push(calendarMonth))
      .catch(console.error)
  }, [calendarMonth])

  function getDayColor(keyType: PresenceTimeKeyType | null | undefined) {
    if (!keyType) return undefined
    return getDayColorByKeyType(keyType)
  }

  function handleDayPressed(day: Date) {
    if (!allowEdit) return
    setDate(day, UpdateSources.DAY_PRESS)
    createPresenceTimeModal.show()
  }

  function handlePresenceTimeModalClosed() {
    if (!date) return
    loadedMonths.current = loadedMonths.current.filter(d => !isSameMonth(d, date))
    setCalendarMonth(new Date(date.getTime()))
  }

  function createMarkedDates(presenceTimes: PresenceTimesOfDay[] | undefined, keys: PresenceTimeKey[] | undefined) {
    return PresenceTimeCalendarUtils.convertTimesToMarkedDates(presenceTimes, keys).reduce(
      (result, markedDate) => ({
        ...result,
        [TimeUtils.getDateForRequest(markedDate.date)]: {
          selected: true,
          dotColor: getDayColor(markedDate.type),
          marked: true,
          startingDay: markedDate.startingDay,
          endingDay: markedDate.endingDay,
          color: getDayColor(markedDate.type),
          textColor: isSameDay(markedDate.date, new Date()) ? 'black' : undefined,
        },
      }),
      {}
    )
  }

  return (
    <IM.View style={[IMLayout.flex.f1, styles.calendarContainer]}>
      <CalendarList
        current={initialDate.toDateString()}
        markedDates={markedDates}
        markingType="period"
        onMonthChange={monthChangedDate => setCalendarMonth(new Date(monthChangedDate.timestamp))}
        customHeader={CalendarHeader}
        onDayPress={day => handleDayPressed(new Date(day.dateString))}
        displayLoadingIndicator={loading}
        theme={theme.presenceTime.calendar}
        pastScrollRange={12}
        futureScrollRange={1}
        firstDay={1}
        minDate={minEditDate?.toDateString()}
        calendarStyle={styles.calendar}
      />
      <CreatePresenceTimeModal controller={createPresenceTimeModal} onClose={handlePresenceTimeModalClosed} date={date} calendarMode />
    </IM.View>
  )
}

function CalendarHeader(props: CalendarHeaderProps) {
  const { language } = useLanguage()

  const date = useMemo(() => new Date(props.month), [props.month])
  const dateText = useMemo(() => DateUtils.formatDate(date, 'MMMM yyyy', language), [date])
  const weekDays = useMemo(() => {
    const arr = TimeUtils.getWeek(startOfWeek(date, { weekStartsOn: 1 }))
    return arr.reduce((a, d) => {
      a.push(DateUtils.formatDate(d, language === 'de' ? 'EEEEEE' : 'EEE', language))
      return a
    }, [] as string[])
  }, [])
  const isLoading = !!props.displayLoadingIndicator

  return (
    <IM.View style={styles.calendarHeader}>
      <IM.View style={styles.headerView} spacing="bottom">
        {isLoading && <IM.View style={styles.loadingSpinnerView} />}
        <IM.View style={IMLayout.flex.f1}>
          <IM.Text style={styles.dateText}>{dateText}</IM.Text>
        </IM.View>
        {isLoading && (
          <IM.View style={styles.loadingSpinnerView}>
            <LoadingIndicator isVisible={true} size={25} />
          </IM.View>
        )}
      </IM.View>

      <IM.View style={styles.calendarHeaderWeekDaysView}>
        {weekDays.map(day => (
          <IM.View style={[IMLayout.flex.f1, styles.weekDayView]} key={'WeekDay' + day}>
            <IM.Text secondary style={[IMLayout.flex.f1, styles.calendarHeaderText]}>
              {day}
            </IM.Text>
          </IM.View>
        ))}
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  calendarHeader: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  calendarHeaderWeekDaysView: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  calendarHeaderText: {
    textAlign: 'right',
  },
  weekDayView: {
    alignItems: 'center',
  },
  headerView: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30, //Used to avoid "jumping" of views when LoadingSpinner is shown
  },
  dateText: {
    fontSize: IMStyle.typography.fontSizeRegular,
    flex: 1,
    alignSelf: 'center',
  },
  loadingSpinnerView: {
    width: 50,
  },
  calendar: { maxWidth: 500 },
  calendarContainer: { alignItems: 'center' },
})
