import { ApiClientProvider } from '@infominds/react-api'
import { IM, IMStyle, useAlert, useLanguage } from '@infominds/react-native-components'
import { _INTERNAL_LicenseAuthenticationGlobals, _INTERNAL_LicenseDataGlobals, AuthenticationContainer } from '@infominds/react-native-license'
import React, { ReactNode } from 'react'
import { ActivityIndicator, Platform, View } from 'react-native'
import BootSplash from 'react-native-bootsplash'
import { useSetRecoilState } from 'recoil'

import { apiVersionAtom } from '../utils/stateManager'
import version from '../versions.json'

type Props = { children: ReactNode | (({ authReady, isLoggedIn }: { authReady: boolean; isLoggedIn: boolean }) => ReactNode) }

export default function AuthenticationWrapper({ children }: Props) {
  const { language } = useLanguage()
  const { alert } = useAlert()

  const setApiVersion = useSetRecoilState(apiVersionAtom)

  return (
    <AuthenticationContainer
      config={{
        aad: true,
        apiType: '.net',
        projectCode: 'APP-RXTIME',
        moduleCode: 'APP',
        debug: __DEV__,
        isSinglePageApp: true,
      }}
      locale={language}
      versionsFile={version}
      onVersionCheckEnd={({ error, apiVersion }) => {
        setApiVersion(apiVersion)
        error && alert(error.title, error.message)
      }}>
      {authContext => {
        const authReady = authContext && authContext.isLoggedIn !== undefined

        authReady && BootSplash.hide().catch(console.error)

        if (authContext === undefined || authReady === undefined || authContext.isLoggedIn === undefined) {
          // IM.View does not work here
          return (
            <>
              {Platform.OS !== 'web' ? (
                <IM.LoadingSpinner isVisible />
              ) : (
                // eslint-disable-next-line react-native/no-inline-styles
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <ActivityIndicator size="large" color={IMStyle.palette.tint} />
                </View>
              )}
            </>
          )
        }

        return (
          <ApiClientProvider
            globalAuthenticationData={_INTERNAL_LicenseAuthenticationGlobals}
            globalConnectionData={_INTERNAL_LicenseDataGlobals}
            urlPrefix="/api/"
            debugLog
            useEncoding>
            {typeof children === 'function' ? children({ authReady, isLoggedIn: authContext.isLoggedIn }) : children}
          </ApiClientProvider>
        )
      }}
    </AuthenticationContainer>
  )
}
