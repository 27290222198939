import { IM, IMLayout, IMStyle, useTheme } from '@infominds/react-native-components'
import { Profile, useAuthentication } from '@infominds/react-native-license'
import React, { useEffect, useMemo, useState } from 'react'
import { Image, StyleSheet } from 'react-native'

import EmployeeBadgePlaceholderText from '../components/EmployeeBadgePlaceholderText'
import SkeletonText from '../components/Infominds/Skeleton/SkeletonText'
import { ThemeColorExpanded } from '../types'

export default function UserCard() {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { name: authName, loginType, profile: getProfile } = useAuthentication()

  const [profile, setProfile] = useState<Profile | undefined>(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (loginType === 'AAD') {
      getProfile?.()
        .then(val => {
          setProfile(val)
          setLoading(false)
        })
        .catch(console.error)
    } else {
      setLoading(false)
    }
  }, [loginType])

  const calculateInitials = (inputString: string) => {
    if (!inputString) return ''

    const simplifiedString = inputString
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .split(' ')
      .filter(el => el !== '')
    let initial = ''

    if (simplifiedString.length === 0) {
      initial = Math.random().toString(36).slice(2).substring(2, 4).toUpperCase()
    } else if (simplifiedString.length === 1) {
      initial = simplifiedString[0][0].toUpperCase() + Math.random().toString(36).slice(2).substring(2, 3).toUpperCase()
    } else {
      initial = `${simplifiedString[0][0].toUpperCase()}${simplifiedString[1][0].toUpperCase()}`
    }

    return initial
  }

  const name = profile?.givenName
  const surname = profile?.surname
  const initials = useMemo(() => (authName ? calculateInitials(authName) : ''), [authName])

  if (!((loginType === 'Default' && initials !== '') || loginType === 'AAD')) return <></>

  return (
    <IM.Card style={[styles.card, { backgroundColor: theme.settings.background }]} borderless>
      {loading === false ? (
        <>
          {name && surname ? (
            <IM.View style={[IMLayout.flex.row, styles.employeeBadgeContainer]}>
              {/* eslint-disable-next-line react-native/no-inline-styles */}
              <Image source={{ uri: profile.picture }} style={{ width: 80, aspectRatio: 1, borderRadius: 50 }} />
              {/* eslint-disable-next-line react-native/no-inline-styles */}
              <IM.View style={{ marginLeft: 12 }}>
                <IM.Text style={{ fontSize: IMStyle.typography.fontSizeRegular }} allowFontScaling={false}>
                  {name}
                </IM.Text>
                <IM.Text
                  style={{ fontSize: IMStyle.typography.fontSizeRegular, fontWeight: IMStyle.typography.fontWeightBold }}
                  allowFontScaling={false}>
                  {surname}
                </IM.Text>
              </IM.View>
            </IM.View>
          ) : (
            <IM.View style={styles.defaultInfoContainer}>
              <IM.View style={[styles.defaultInfoBadge, { backgroundColor: theme.checkbox.tint }]}>
                <EmployeeBadgePlaceholderText initials={initials} size={36} />
              </IM.View>
              <IM.Text
                style={{ fontSize: IMStyle.typography.fontSizeRegular, fontWeight: IMStyle.typography.fontWeightBold }}
                allowFontScaling={false}>
                {authName}
              </IM.Text>
            </IM.View>
          )}
        </>
      ) : (
        <IM.View style={styles.skeleton}>
          <IM.View spacing="top">
            <SkeletonText />
          </IM.View>
          <IM.View spacing="vertical">
            <SkeletonText />
          </IM.View>
          <IM.View spacing="bottom">
            <SkeletonText />
          </IM.View>
        </IM.View>
      )}
    </IM.Card>
  )
}

const styles = StyleSheet.create({
  card: { marginTop: 12, padding: 0, borderRadius: 25 },
  employeeBadgeContainer: { alignItems: 'center' },
  row: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
  skeleton: {
    paddingTop: 2,
    paddingBottom: 1,
  },
  defaultInfoContainer: { flexDirection: 'row', alignItems: 'center' },
  defaultInfoBadge: { height: 40, aspectRatio: 1, borderRadius: 50, alignItems: 'center', justifyContent: 'center', marginRight: 16 },
})
