import { IM, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { createRef, useEffect } from 'react'
import { BackHandler } from 'react-native'
import { useRecoilState, useRecoilValue } from 'recoil'

import { ScreenHeader } from '../../components/ScreenHeader'
import { InfoBoxStackScreenProps } from '../../navigation/type'
import { assetsToSyncAtom, mediaSortingMethodInfoboxAtom } from '../../utils/stateManager'
import InfoBoxMediaView, { InfoboxMediaViewImperativeMethods } from '../../views/InfoBox/InfoBoxMediaView'
import { mediaUtils } from '@infominds/react-native-media'

const InfoBoxMediaScreen = ({ route, navigation }: InfoBoxStackScreenProps<'InfoboxMedia'>) => {
  const { i18n } = useLanguage()
  const { sessionKey } = useAuthentication()

  const mediaRef = createRef<InfoboxMediaViewImperativeMethods>()

  const assetToSync = useRecoilValue(assetsToSyncAtom)
  const [displayMethod, setDisplayMethod] = useRecoilState(mediaSortingMethodInfoboxAtom(sessionKey))

  useEffect(() => {
    const subscription = BackHandler.addEventListener('hardwareBackPress', () => {
      customGoBack()
      return true
    })

    return () => subscription.remove()
  }, [assetToSync])

  function handlePress() {
    if (assetToSync > 0) {
      mediaRef.current?.handleUpload()
    } else {
      setDisplayMethod(mediaUtils.getFollowingDisplayMethod(displayMethod))
    }
  }

  function customGoBack() {
    if (assetToSync > 0) {
      mediaRef.current?.mediaAlert()
    } else {
      navigation.goBack()
    }
  }

  return (
    <IM.Screen
      spacing="bottom"
      screenHeader={
        <ScreenHeader
          goBack={customGoBack}
          title={route.params.document.code}
          subTitle={{
            text: route.params.folder?.description ?? i18n.t('FOLDER_WITHOUT_NAME'),
            icon: ['fal', 'folder-open'],
          }}
          icon={assetToSync > 0 ? ['fal', 'check'] : ['fal', displayMethod === 'twice' ? 'list' : displayMethod === 'details' ? 'square' : 'grid-2']}
          onIconPress={handlePress}
        />
      }>
      <InfoBoxMediaView
        ref={mediaRef}
        id={route.params.document.id}
        infoboxType={route.params.document.documentType}
        folderNumber={route.params.folder?.number}
        folderRight={route.params.folder?.right}
      />
    </IM.Screen>
  )
}

export default InfoBoxMediaScreen
