import { IM, ModalController, useLanguage } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'

import { Document, EmployeeTimeType } from '../../apis/types/apiResponseTypes'
import LastUsedTimesCard from '../../cards/LastUsedTimesCard'
import LoadingSpinnerModal from '../../components/Infominds/LoadingSpinnerModal'
import { useEmployeeTime } from '../../contexts/EmployeeTimeContext'
import { useToast } from '../../contexts/ToastReferenceContext'
import useLastUsedTimes from '../../hooks/useLastUsedTimes'
import useLayout from '../../hooks/useLayout'
import type { NewTimeModalData } from '../../modals/NewTimeModal'
import { HomeStackParamList } from '../../navigation/type'
import type { LastUsedTimeData } from '../../types'
import DocumentUtils from '../../utils/DocumentUtils'
import EmployeeUtils from '../../utils/EmployeeUtils'

interface Props {
  controller: ModalController<NewTimeModalData>
}

export default function HomeTimeSelectionView({ controller }: Props) {
  const { i18n } = useLanguage()
  const navigation = useNavigation<NavigationProp<HomeStackParamList>>()
  const lastUsed = useLastUsedTimes()
  const employeeTime = useEmployeeTime()
  const toast = useToast()
  const { isSmallDevice } = useLayout()
  const [validating, setValidating] = useState(false)

  function handleNewTimeNavigation(result: { activity: EmployeeTimeType; document: Document | undefined } | void) {
    if (isSmallDevice) {
      navigation.navigate('NewTime', result ? { activity: result.activity, document: result.document } : undefined)
    } else {
      controller.show(result ? { activity: result.activity, document: result.document } : {})
    }
  }

  function handleLastUsedPressed(item: LastUsedTimeData) {
    setValidating(true)
    //Get updated LastUsedTime Data
    EmployeeUtils.getUpdatedLastUsedTimeData(item)
      .then(result => {
        //if data was found update the item and navigate
        if (result) {
          lastUsed.updateItem(item, result)
          handleNewTimeNavigation({ activity: result.activity, document: result.document })
        } else {
          //If no data was found show error and delete the item
          toast.show(i18n.t('LAST_USED_TIME_NOT_AVAILABLE'), { type: 'danger' })
          lastUsed.removeItem(item)
        }
      })
      .catch(console.error)
      .finally(() => setValidating(false))
  }

  return (
    <>
      {!employeeTime.isTimeActive && !!lastUsed.items.length && (
        <>
          <IM.View spacing="vertical">
            <IM.Text>{i18n.t('LAST_USED')}</IM.Text>
          </IM.View>
          {lastUsed.items.slice(0, 3).map(item => (
            <LastUsedTimesCard
              key={`LastUsedItem${item.activity.id}${item.document?.id ?? ''}`}
              data={item}
              spacing="bottom"
              onPress={() => handleLastUsedPressed(item)}
            />
          ))}
        </>
      )}
      {!!employeeTime.isTimeActive && employeeTime.currentTime && (
        <>
          <IM.View spacing="vertical">
            <IM.Text>{i18n.t('ACTIVE_TIME')}</IM.Text>
          </IM.View>
          <LastUsedTimesCard
            data={{
              activity:
                employeeTime.getEmployeeTimeTypeById(employeeTime.currentTime.employeeTimeTypeId) ??
                EmployeeUtils.createEmployeeTypeFromEmployeeTime(employeeTime.currentTime),
              document: DocumentUtils.createDocumentFromEmployeeTime(employeeTime.currentTime),
            }}
            spacing="bottom"
            onPress={handleNewTimeNavigation}
          />
        </>
      )}
      {!controller.isShown && <LoadingSpinnerModal isVisible={validating} />}
    </>
  )
}
