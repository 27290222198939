import { useInfomindsAnalyticsNavigation } from '@infominds/react-native-analytics'
import { OptionsProvider } from '@infominds/react-native-camera'
import { useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import { AssetProvider } from '@infominds/react-native-media'
import { DarkTheme, DefaultTheme, NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack'
import React, { useCallback, useEffect } from 'react'
import { Platform } from 'react-native'
import { useSetRecoilState } from 'recoil'

import TabNavigator from '../components/TabNavigator'
import { HIDE_NAVIGATION_EVENT_KEY } from '../constants/EmitterKeys'
import { EmployeeTimeContextProvider } from '../contexts/EmployeeTimeContext'
import { HistoryContextProvider } from '../contexts/HistoryContext'
import { PresenceTimeContextProvider } from '../contexts/PresenceTimeContext'
import useIsEmployeeTimeEnabled from '../hooks/useIsEmployeeTimeEnabled'
import useIsPresenceTimeEnabled from '../hooks/useIsPresenceTimeEnabled'
import HistoryScreen from '../screens/History/HistoryScreen'
import HomeScreen from '../screens/Home/HomeScreen'
import NewTimeScreen from '../screens/Home/NewTimeScreen'
import InfoBoxAssetScreen from '../screens/InfoBox/InfoBoxAssetScreen'
import InfoBoxCameraScreen from '../screens/InfoBox/InfoBoxCameraScreen'
import InfoBoxFolderScreen from '../screens/InfoBox/InfoBoxFolderScreen'
import InfoBoxMediaScreen from '../screens/InfoBox/InfoBoxMediaScreen'
import InfoBoxScreen from '../screens/InfoBox/InfoBoxScreen'
import AppLoginScreen from '../screens/Login/LoginScreen'
import PresenceTimeScreen from '../screens/PresenceTime/PresenceTimeScreen'
import SettingsScreen from '../screens/Settings/SettingsScreen'
import { TabNavigationScreen } from '../types'
import { landingPageUrlAtom } from '../utils/stateManager'
import {
  HistoryStackParamList,
  HomeStackParamList,
  InfoBoxStackParamList,
  PresenceTimeStackParamList,
  RootStackParamList,
  SettingsStackParamList,
} from './type'

const screenOptions: NativeStackNavigationOptions = {
  headerShown: false,
}

const Stack = createNativeStackNavigator<RootStackParamList>()

type Props = {
  isLoggedIn: boolean
}

export default function Navigator({ isLoggedIn }: Props) {
  const { i18n } = useLanguage()
  const { colorScheme, theme } = useTheme()

  const isEmployeeTimeEnabled = useIsEmployeeTimeEnabled()
  const isPresenceTimeEnabled = useIsPresenceTimeEnabled()

  const setLandingPage = useSetRecoilState(landingPageUrlAtom)

  const { emit } = useEvent<boolean>({ key: HIDE_NAVIGATION_EVENT_KEY })

  const bottomTabs: TabNavigationScreen[] = [
    {
      id: 0,
      name: 'HomeStack',
      component: HomeStackNavigator,
      icon: ['fal', 'user-clock'],
      title: i18n.t('TAB_EMPLOYEE_TIMES'),
      visible: isEmployeeTimeEnabled,
      iconSize: 23,
    },
    {
      id: 1,
      name: 'PresenceTimeStack',
      component: PresenceTimeStackNavigator,
      icon: ['fal', 'business-time'],
      title: i18n.t('TAB_PRESENCE_TIMES'),
      visible: isPresenceTimeEnabled,
      iconSize: 23,
    },
    {
      id: 2,
      name: 'HistoryStack',
      component: HistoryStackNavigator,
      icon: ['fal', 'calendars'],
      title: i18n.t('TAB_HISTORY'),
      iconSize: 20,
    },
    {
      id: 3,
      name: 'InfoBoxStack',
      component: InfoBoxStackNavigator,
      icon: ['fal', 'photo-film-music'],
      title: i18n.t('TAB_INFOBOX'),
      visible: isEmployeeTimeEnabled,
      iconSize: 23,
    },
    {
      id: 4,
      name: 'SettingsStack',
      component: SettingsStackNavigator,
      icon: ['fal', 'cog'],
      title: i18n.t('SETTINGS'),
      iconSize: 21,
    },
  ]

  useEffect(() => {
    Platform.OS === 'web' && setLandingPage(window.location.href)
  }, [])

  const { navigationRef, onReady, onStateChange: onStateChangeAnalytics } = useInfomindsAnalyticsNavigation<keyof RootStackParamList>()

  const onStateChange = useCallback(() => {
    onStateChangeAnalytics()

    const currentRoute = navigationRef.getCurrentRoute()

    if (currentRoute?.name.includes('InfoboxNoBottomTab')) {
      emit(true)
    } else {
      emit(false)
    }
  }, [])

  return (
    <AssetProvider>
      <NavigationContainer
        ref={navigationRef}
        onReady={onReady}
        onStateChange={onStateChange}
        linking={{
          enabled: false,
          prefixes: [],
        }}
        theme={colorScheme === 'light' ? DefaultTheme : DarkTheme}>
        <Stack.Navigator
          initialRouteName="Login"
          screenOptions={{
            ...screenOptions,
            navigationBarColor: theme.tabNavigator.background,
          }}>
          {!isLoggedIn ? (
            <Stack.Screen name="Login" component={AppLoginScreen} />
          ) : (
            <Stack.Screen name="BottomTab">{() => <TabNavigator tabs={bottomTabs} initialRouteName="HomeStack" />}</Stack.Screen>
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </AssetProvider>
  )
}

const HomeStack = createNativeStackNavigator<HomeStackParamList>()
function HomeStackNavigator() {
  return (
    <EmployeeTimeContextProvider>
      <HomeStack.Navigator screenOptions={screenOptions}>
        <HomeStack.Screen name="Home" component={HomeScreen} />
        <HomeStack.Screen name="NewTime" component={NewTimeScreen} />
      </HomeStack.Navigator>
    </EmployeeTimeContextProvider>
  )
}

const PresenceTimeStack = createNativeStackNavigator<PresenceTimeStackParamList>()
function PresenceTimeStackNavigator() {
  return (
    <PresenceTimeContextProvider>
      <PresenceTimeStack.Navigator screenOptions={screenOptions}>
        <PresenceTimeStack.Screen name="PresenceTime" component={PresenceTimeScreen} />
      </PresenceTimeStack.Navigator>
    </PresenceTimeContextProvider>
  )
}

const HistoryStack = createNativeStackNavigator<HistoryStackParamList>()
function HistoryStackNavigator() {
  return (
    <HistoryContextProvider>
      <HistoryStack.Navigator screenOptions={screenOptions}>
        <HistoryStack.Screen name="History" component={HistoryScreen} />
        <HistoryStack.Screen name="HistoryNewTime" component={NewTimeScreen} />
      </HistoryStack.Navigator>
    </HistoryContextProvider>
  )
}

const InfoBoxStack = createNativeStackNavigator<InfoBoxStackParamList>()
function InfoBoxStackNavigator() {
  return (
    <OptionsProvider>
      <InfoBoxStack.Navigator screenOptions={screenOptions}>
        <InfoBoxStack.Screen name="Infobox" component={InfoBoxScreen} />
        <InfoBoxStack.Screen name="InfoboxFolder" component={InfoBoxFolderScreen} />
        <InfoBoxStack.Screen name="InfoboxMedia" component={InfoBoxMediaScreen} />
        <InfoBoxStack.Screen
          name="InfoboxNoBottomTabCamera"
          component={InfoBoxCameraScreen}
          options={{
            presentation: 'fullScreenModal',
            navigationBarColor: '#000000',
          }}
        />
        <InfoBoxStack.Screen
          name="InfoboxNoBottomTabAsset"
          component={InfoBoxAssetScreen}
          options={{
            animation: 'slide_from_bottom',
            presentation: 'fullScreenModal',
            navigationBarColor: '#000000',
          }}
        />
        {/* <InfoBoxStack.Screen
          name="InfoboxNoBottomTabAssetInfo"
          component={InfoboxAssetInfoScreen}
          options={{
            animation: 'slide_from_bottom',
            presentation: 'fullScreenModal',
            navigationBarColor: '#000000',
          }}
        /> */}
      </InfoBoxStack.Navigator>
    </OptionsProvider>
  )
}

const SettingsStack = createNativeStackNavigator<SettingsStackParamList>()
function SettingsStackNavigator() {
  return (
    <SettingsStack.Navigator screenOptions={screenOptions}>
      <SettingsStack.Screen name="Settings" component={SettingsScreen} />
    </SettingsStack.Navigator>
  )
}
