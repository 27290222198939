import { IM, IMStyle, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Modal, StyleSheet } from 'react-native'

export type UploadModalData = {
  total: number
}
type Props = {
  controller: ModalController<UploadModalData>
  current: number
}

const UploadModal = ({ controller, current }: Props) => {
  const { theme, colorScheme } = useTheme()
  const { i18n } = useLanguage()

  const opacity = colorScheme === 'light' ? 0.45 : 0.65

  return (
    <Modal visible={controller.isShown} transparent statusBarTranslucent>
      <IM.View style={[styles.centeredView, { backgroundColor: `#000000${opacity * 100 * 1.2}` }]}>
        <IM.View style={[styles.modalView, { backgroundColor: theme.modal.background }]}>
          <IM.View spacing="bottom" style={styles.header}>
            <IM.Text style={{ fontSize: IMStyle.typography.fontSizeRegular + 2, fontWeight: IMStyle.typography.fontWeightMedium }}>
              {i18n.t('SYNC')}
            </IM.Text>
            <IM.View>
              <IM.LoadingSpinner isVisible size="small" />
            </IM.View>
          </IM.View>
          {controller.data && <IM.Text>{`${i18n.t('SYNCING')} ${current} / ${controller.data.total}`}</IM.Text>}
        </IM.View>
      </IM.View>
    </Modal>
  )
}

export default UploadModal

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 14 },
  modalView: {
    borderRadius: 20,
    paddingHorizontal: 30,
    paddingTop: 28,
    paddingBottom: 28,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '90%',
    maxWidth: 400,
  },
})
