import { IM, IMStyle, useTheme, Utils } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { DimensionValue, StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../types'

export type DataBarProps<T> = {
  elements: T[]
  elementDataProvider: (element: T) => { value: number; color?: string }
  totalValue?: number
  backGroundColor?: string
  sortBySize?: boolean
}

type DataBarElement = {
  value: number
  color: string | undefined
}

const minBarWidthRatio = 0.03
export default function DataBar<T>({ elements, elementDataProvider, totalValue, sortBySize, backGroundColor }: DataBarProps<T>) {
  const { theme } = useTheme<ThemeColorExpanded>()

  const elementTotalValue = Math.max(
    Utils.sum(elements, element => Math.max(elementDataProvider(element).value, 0)),
    0
  )
  const total = Math.max(totalValue ?? 0, elementTotalValue)

  const barElements = useMemo(() => {
    const minDataValue = Math.round(total * minBarWidthRatio)
    let sum = 0
    const elementsToAdd: DataBarElement[] = []
    for (const e of elements) {
      const data = elementDataProvider(e)
      if (data.value <= 0) continue
      const value = Math.max(data.value, minDataValue)
      elementsToAdd.push({ value, color: data.color })
      sum += value
    }
    if (sum > total) {
      const valueToRemove = sum - total
      const largeElements = elementsToAdd.filter(e => e.value > total * 0.1)
      largeElements.forEach(e => {
        e.value -= Math.round(valueToRemove / largeElements.length)
      })
    }
    if (sortBySize) {
      elementsToAdd.sort((a, b) => b.value - a.value)
    }
    return elementsToAdd
  }, [elements, elementDataProvider, total])

  const barLength = (ratio: number): DimensionValue => {
    return isNaN(ratio) ? '100%' : `${ratio * 100}%`
  }

  function getColorByIndex(index: number) {
    return theme.randomColors[index % theme.randomColors.length]
  }

  return (
    <IM.View style={styles.barContainer}>
      <IM.View
        style={[
          styles.barRowContainer,
          {
            width: barLength(1),
            backgroundColor: theme.card.background,
          },
        ]}>
        <IM.View
          style={[
            styles.barRow,
            {
              borderRadius: IMStyle.layout.borderRadius,
              backgroundColor: backGroundColor ?? theme.bar.background,
              borderColor: theme.card.background,
            },
          ]}
        />
      </IM.View>
      {barElements.map((element, index) => {
        return (
          <IM.View
            key={`DataBarElement${index}`}
            style={[
              styles.barRowContainer,
              {
                width: barLength(Utils.sum(barElements.slice(0, index + 1), e => e.value) / total),
                backgroundColor: theme.card.background,
                zIndex: elements.length + 2 - index,
              },
            ]}>
            <IM.View
              style={[
                styles.barRow,
                {
                  borderRadius: IMStyle.layout.borderRadius,
                  backgroundColor: element.color || getColorByIndex(index),
                  borderColor: theme.card.background,
                },
              ]}
            />
          </IM.View>
        )
      })}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  barContainer: { height: IMStyle.typography.fontSizeSmall },
  barRowContainer: { position: 'absolute', top: 0, left: 0, borderRadius: IMStyle.layout.borderRadius, height: '100%' },
  barRow: { height: '100%', borderWidth: 1 },
})
