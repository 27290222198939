import type { Document, EmployeeTime } from '../apis/types/apiResponseTypes'

const DocumentUtils = {
  createDocumentFromEmployeeTime(employeeTime: EmployeeTime | undefined): Document | undefined {
    if (!employeeTime?.documentId) return undefined
    return {
      id: employeeTime.documentId ?? '',
      code: employeeTime.document ?? '',
      documentType: employeeTime.documentType ?? '',
      description: employeeTime.description ?? '',
      pspDetailId: employeeTime.pspDetId ?? undefined,
    }
  },
}

export default DocumentUtils
