import { useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import TabSelector from './TabSelector'

export type PresenceTimeTabItem = {
  name: string
  onClick: () => void
}

type PresenceTimeTabsProps = {
  position: number
  items: PresenceTimeTabItem[]
}

export default function PresenceTimeTabs({ position, items }: PresenceTimeTabsProps) {
  console.log('🚀 ~ PresenceTimeTabs ~ position:', position)
  const { colorScheme } = useTheme()

  const activeColor = useMemo(() => (colorScheme === 'light' ? '#333B47' : '#424242'), [colorScheme])

  function handlePress(index: number) {
    items[index].onClick()
  }

  return (
    <TabSelector height={34} selectionColor={activeColor} style={styles.container}>
      {items.map((item, index) => (
        <TabSelector.Item
          key={`${index}_${item.name}`}
          text={item.name}
          selected={index === position}
          onPress={() => handlePress(index)}
          disableDivider={index === items.length - 1}
        />
      ))}
    </TabSelector>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 8,
    marginTop: 12,
    ...Platform.select({ ios: { marginTop: 8, marginBottom: 6, marginHorizontal: 8 } }),
  },
})
